import { NJFormItem } from "@engie-group/fluid-design-system-react";
import { ErrorText } from "../ErrorText";
import { Box, SxProps, Theme } from "@mui/material";

const style = {
	container: {
		width: 162,
	},
};

export interface FluidTextFieldProps {
	onChange: (event: any) => void;
	onBlur?: (event: any) => void;
	value: string | undefined;
	name: string;
	label: string;
	sx?: SxProps<Theme>;
	errorMessage?: string;
	isDisabled?: boolean;
	isReadOnly?: boolean;
	isRequired?: boolean;
}

export const FluidTextField = (props: FluidTextFieldProps) => {
	return (
		<Box sx={{ ...style.container, ...props.sx }}>
			<NJFormItem
				id={props.name}
				value={props.value || ""}
				label={props.label}
				labelKind="static"
				name={props.name}
				onChange={props.onChange}
				title={props.label}
				type="text"
				onBlur={props.onBlur}
				isDisabled={props.isDisabled}
				isRequired={props.isRequired}
				readOnly={props.isReadOnly}
			/>
			<ErrorText>{props.errorMessage}</ErrorText>
		</Box>
	);
};
