import { Box, Tooltip } from "@mui/material";
import { PricingTableRowProps } from "./PricingTableRowProps";

export function PricingTableRowToolTip(props: PricingTableRowProps) {
	const { value, sx } = props;
	return (
		<Tooltip title={value}>
			<Box component={"span"} sx={sx ?? {}}>
				{value}
			</Box>
		</Tooltip>
	);
}
