import { Box } from "@mui/material";
import _ from "lodash";
import {
	ContractDetailType,
	ContractPeriodSettlementCategories,
	ContractPeriodType,
	SettlementType,
} from "../../../../requests_geco/contractsApi/contractsApi.types";
import { Spacer } from "../../../../common/components/Spacer";
import CircularProgress from "@mui/material/CircularProgress";
import { useGetNegativePriceIndexesQuery } from "../../../../requests_geco/referentialApi/referentialApi";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import NoDataMessage from "../../../../common/components/NoDataMessage";
import EditableSettlementCard from "./EditableSettlementCard";
import { useCallback, useMemo } from "react";
import { settlementFormikToContractMapper } from "../../formik/settlementFormikToContractMapper";

export const SettlementTab = ({
	contract,
	contractPeriodId,
	onSaveDraftContract,
}: {
	contract?: ContractDetailType;
	contractPeriodId?: number;
	onSaveDraftContract: (contract: ContractDetailType) => void;
}) => {
	const { data: indexes, isLoading } = useRtkQueryDynamicEndpoint(
		useGetNegativePriceIndexesQuery
	)({
		country_code: contract?.country ?? "",
	});

	const contractPeriod: ContractPeriodType | undefined = useMemo(() => {
		return contract?.contract_periods.filter(
			(cp: ContractPeriodType) => cp.id === Number(contractPeriodId)
		)[0];
	}, [contract]);

	const settlementCategory: ContractPeriodSettlementCategories | undefined =
		useMemo(() => {
			for (const key of Object.keys(ContractPeriodSettlementCategories)) {
				if (_.get(contractPeriod, key)) {
					return key as ContractPeriodSettlementCategories;
				}
			}
		}, [contractPeriod]);

	const settlements: SettlementType[] = useMemo(() => {
		if (contractPeriod && settlementCategory) {
			return contractPeriod[settlementCategory].settlements;
		}
		return [];
	}, [contractPeriod, settlementCategory]);

	const updateSettlementOnContract = useCallback(
		(formikSettlement: SettlementType) => {
			onSaveDraftContract(
				settlementFormikToContractMapper(
					formikSettlement,
					contract as ContractDetailType,
					contractPeriodId as number,
					settlementCategory as ContractPeriodSettlementCategories
				)
			);
		},
		[settlementCategory, contractPeriodId, contract]
	);

	return (
		<>
			{isLoading && <CircularProgress />}
			{!isLoading && (
				<Box sx={{ mt: 3 }}>
					<Spacer gap={24} />
					{!settlements.length && <NoDataMessage />}
					{!!settlements.length && (
						<Box
							sx={{
								display: "flex",
								gap: 3,
								flexWrap: "wrap",
							}}
						>
							{(settlements || []).map((settlement, index) => (
								<EditableSettlementCard
									key={index}
									settlement={settlement}
									isLoading={false}
									indexes={indexes || []}
									onSubmit={updateSettlementOnContract}
								/>
							))}
						</Box>
					)}
				</Box>
			)}
		</>
	);
};
