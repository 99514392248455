import { NJButton } from "@engie-group/fluid-design-system-react";
import { If } from "../If";

export interface FluidButtonProps {
	label: string;
	icon?: string;
	type?: "button" | "submit" | "reset";
	onClick: () => void;
	isDisabled?: boolean;
	isLoading?: boolean;
	emphasis?: "bold" | "subtle" | "minimal";
	variant?: "inverse" | "primary" | "secondary" | "destructive";
}
export const FluidButton = ({
	type,
	icon,
	label,
	onClick,
	isDisabled,
	isLoading,
	emphasis,
	variant = undefined,
}: FluidButtonProps) => {
	return (
		<If condition={!isLoading}>
			<NJButton
				variant={variant}
				type={type}
				icon={icon}
				label={label}
				onClick={onClick}
				isDisabled={isDisabled}
				emphasis={emphasis}
			/>
		</If>
	);
};
