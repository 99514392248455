import { Tab } from "./Tab";

export interface ContractPeriodTabProps extends React.PropsWithChildren<{}> {
	label?: string;
	id?: string;
	isActive?: boolean;
	onClick?: () => void;
	isDisabled?: boolean;
}

export const SingleTab = (props: ContractPeriodTabProps) => {
	return (
		<Tab
			isActive={props.isActive}
			label={props.label || ""}
			id={props.id || ""}
			isDisabled={props.isDisabled}
			onClick={props.onClick}
		>
			{props.children ? props.children : null}
		</Tab>
	);
};
