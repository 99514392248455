import {
	LinqPredicate,
	applyLinqPredicates,
	buildPredicates,
} from "../filters/utils";
import { ComputedFilter } from "../metadata/metadata.module";
import { Pricing, TenderStatus } from "../pricing_list/pricingListSlice";
import * as _ from "lodash";
import { Tender } from "../tender_page/tender.module";
import { getTimestampWithTimeZone } from "./utils";
import differenceInDays from "date-fns/differenceInDays";
import { getTodaysDate } from "../../common/utils/dateUtils";

export function mergeIncomingPricing(
	list: Pricing[],
	incomingPricing: Pricing,
	filters: ComputedFilter[],
	extraPredicates?: LinqPredicate[]
): Pricing[] {
	const latestPricings = _.uniqBy(
		_.orderBy(
			[...list, incomingPricing],
			[
				"pricing_group_id",
				"id",
				(pricing) =>
					Math.max(
						getTimestampWithTimeZone(pricing.updated_at),
						getTimestampWithTimeZone(
							pricing.pricing_runs?.[0]?.updated_at
						)
					),
			],
			["desc", "desc", "desc"]
		),
		"id"
	);
	const filterValues = _.fromPairs(
		filters.map((filter) => [filter.field_key, filter.value])
	);
	const predicates = buildPredicates(filters, filterValues, "Pricings");
	const allPredicates = extraPredicates
		? [...predicates.linqPredicates, ...extraPredicates]
		: predicates.linqPredicates;
	const final = applyLinqPredicates(latestPricings, allPredicates);
	return final;
}

export function mergeIncomingPricings(
	list: Pricing[],
	incomingPricings: Pricing[],
	filters: ComputedFilter[],
	extraPredicates?: LinqPredicate[]
): Pricing[] {
	const latestPricings = _.uniqBy(
		_.orderBy(
			[...list, ...incomingPricings],
			[
				"pricing_group_id",
				"id",
				(pricing) =>
					Math.max(
						getTimestampWithTimeZone(pricing.updated_at),
						getTimestampWithTimeZone(
							pricing.pricing_runs?.[0]?.updated_at
						)
					),
			],
			["desc", "desc", "desc"]
		),
		"id"
	);

	const filterValues = _.fromPairs(
		filters.map((filter) => [filter.field_key, filter.value])
	);
	const predicates = buildPredicates(filters, filterValues, "Pricings");
	const allPredicates = extraPredicates
		? [...predicates.linqPredicates, ...extraPredicates]
		: predicates.linqPredicates;
	const final = applyLinqPredicates(latestPricings, allPredicates);

	return final;
}

export function mergeIncomingTender(
	list: Tender[],
	incomingTender: Tender,
	filters: ComputedFilter[],
	extraPredicates: LinqPredicate[]
) {
	let latestTenders = _.uniqBy(
		_.orderBy(
			[...list, incomingTender],
			["id", (tender) => new Date(tender.updated_at).getTime()],
			["desc", "desc"]
		),
		"id"
	);
	latestTenders = _.orderBy(
		latestTenders,
		[
			(tender) => ![TenderStatus.CLOSED].includes(tender.status),
			(tender) => new Date(tender.due_date) < getTodaysDate(),
			(tender) =>
				Math.abs(
					differenceInDays(new Date(tender.due_date), getTodaysDate())
				),
		],
		["desc", "asc", "asc"]
	);

	const filterValues = _.fromPairs(
		filters.map((filter) => [filter.field_key, filter.value])
	);
	const predicates = buildPredicates(filters, filterValues, "Tenders");
	const allPredicates = extraPredicates
		? [...predicates.linqPredicates, ...extraPredicates]
		: predicates.linqPredicates;
	const final = applyLinqPredicates(latestTenders, allPredicates);
	return final;
}

export function mergeIncomingTenders(
	list: Tender[],
	incomingTenders: Tender[],
	filters: ComputedFilter[],
	extraPredicates: LinqPredicate[]
) {
	let latestTenders = _.uniqBy(
		_.orderBy(
			[...list, ...incomingTenders],
			["id", (tender) => new Date(tender.updated_at).getTime()],
			["desc", "desc"]
		),
		"id"
	);
	latestTenders = _.orderBy(
		latestTenders,
		[
			(tender) => ![TenderStatus.CLOSED].includes(tender.status),
			(tender) => new Date(tender.due_date) < getTodaysDate(),
			(tender) =>
				Math.abs(
					differenceInDays(new Date(tender.due_date), getTodaysDate())
				),
		],
		["desc", "asc", "asc"]
	);

	const filterValues = _.fromPairs(
		filters.map((filter) => [filter.field_key, filter.value])
	);
	const predicates = buildPredicates(filters, filterValues, "Tenders");
	const allPredicates = extraPredicates
		? [...predicates.linqPredicates, ...extraPredicates]
		: predicates.linqPredicates;
	const final = applyLinqPredicates(latestTenders, allPredicates);
	return final;
}
