import { useMemo } from "react";
import FiltersDrawer, {
	FiltersField,
	FieldValue,
	Operations,
	retrieveOperationFromFieldName,
	retrieveValueFromFieldName,
} from "../../common/components/FiltersDrawer";
import FluidSelectMultiple from "../../common/components/FluidSelectMulti";
import { noop } from "../../common/utils/operations";
import {
	ContractStatus,
	DealConfigMethods,
} from "../../requests_geco/contractsApi/contractsApi.types";
import { GenericReferential } from "../../requests_geco/referentialApi/referentialApi.types";
import FluidDatePicker from "../../common/components/FluidDatePicker";
import FluidSelect from "../../common/components/FluidSelect";
import { FluidTextField } from "../../common/components/FluidTextField";
import { filtersLabels } from "./filtersLabels";

export type FiltersWithLabelsType = Record<
	string,
	{
		value: FieldValue;
		label: string;
		operation: Operations;
	}
>;

export interface ContractPageFiltersProps {
	countries: GenericReferential[];
	books: GenericReferential[];
	contractTypes: GenericReferential[];
	agreementTypes: GenericReferential[];
	isOpen: boolean;
	defaultFilters?: Record<string, FieldValue>;
	onClose: () => void;
	onApplyFilters: (
		filters: Record<string, FieldValue>,
		filtersWithLabels: Record<
			string,
			{
				value: FieldValue;
				label: string;
				operation: Operations;
			}
		>
	) => void;
}

const ContractPageFilters = ({
	countries,
	books,
	contractTypes,
	agreementTypes,
	isOpen,
	defaultFilters = {},
	onClose,
	onApplyFilters,
}: ContractPageFiltersProps) => {
	const countryOptions = useMemo(() => {
		return countries.map((country) => ({
			label: country.name,
			value: country.id,
		}));
	}, [countries]);

	const booksOptions = useMemo(() => {
		return books.map((book) => ({ label: book.name, value: book.id }));
	}, [books]);

	const contractTypesOptions = useMemo(
		() =>
			contractTypes.map((contractType) => ({
				label: contractType.name,
				value: contractType.id,
			})),
		[contractTypes]
	);

	const agreementTypesOptions = useMemo(() => {
		return agreementTypes.map((agreementType) => ({
			label: agreementType.name,
			value: agreementType.id,
		}));
	}, [agreementTypes]);

	const handleApplyFilters = (filters: Record<string, FieldValue>) => {
		const filteredFilters = Object.fromEntries(
			Object.entries(filters).filter(
				([, value]) =>
					value !== "" && (!Array.isArray(value) || value.length > 0)
			)
		);

		const filtersWithLabels = Object.keys(filteredFilters).reduce(
			(acc, key) => {
				const value = filteredFilters[key];
				const name =
					Object.keys(filtersLabels).find((filterKey) =>
						key.includes(filterKey)
					) || key;
				const label = filtersLabels[name]?.label || key;
				const operationKey = key
					.split("__")
					.pop() as keyof typeof Operations;
				const operation: Operations = (Object.values(Operations).find(
					(op) => String(op) === operationKey
				) || "") as Operations;

				if (Array.isArray(value)) {
					switch (name) {
						case "country_id":
							acc[name] = {
								value: value.map(
									(val) =>
										countryOptions.find(
											(option) => option.value === val
										)?.label || val
								),
								label,
								operation,
							};
							break;
						case "book_id":
							acc[name] = {
								value: value.map(
									(val) =>
										booksOptions.find(
											(option) =>
												Number(option.value) ===
												Number(val)
										)?.label || "not found"
								),
								label,
								operation,
							};
							break;
						case "contract_type_id":
							acc[name] = {
								value: value.map(
									(val) =>
										contractTypesOptions.find(
											(option) => option.value === val
										)?.label || val
								),
								label,
								operation,
							};
							break;
						case "contractperiods__dealconfigs__method":
							acc[name] = {
								value: value.map(
									(val) =>
										Object.values(DealConfigMethods).find(
											(option) => option === val
										) || val
								),
								label,
								operation,
							};
							break;
						case "agreement_type_id":
							acc[name] = {
								value: value.map(
									(val) =>
										agreementTypesOptions.find(
											(option) => option.value === val
										)?.label || val
								),
								label,
								operation,
							};
							break;
						default:
							acc[name] = { value, label, operation };
							break;
					}
				} else {
					acc[name] = { value, label, operation };
				}
				return acc;
			},
			{} as FiltersWithLabelsType
		);

		onApplyFilters(filters, filtersWithLabels);
	};

	return (
		<FiltersDrawer
			title={"Contract Filters"}
			isOpen={isOpen}
			onClose={onClose}
			onApplyFilters={handleApplyFilters}
			defaultFilters={defaultFilters}
		>
			<FiltersField
				name={filtersLabels.status.key}
				label={filtersLabels.status.label}
				defaultOperation={
					retrieveOperationFromFieldName("status", defaultFilters) ||
					Operations.EQ
				}
				defaultFieldValue={
					retrieveValueFromFieldName("status", defaultFilters) || []
				}
				allowedOperations={[Operations.EQ, Operations.NOT]}
				selector={(data: string) => data}
				Input={
					<FluidSelect
						isLabelStatic
						items={[
							{
								label: ContractStatus.DRAFT,
								value: ContractStatus.DRAFT,
							},
							{
								label: ContractStatus.VALID,
								value: ContractStatus.VALID,
							},
							{
								label: ContractStatus.TO_REVIEW,
								value: ContractStatus.TO_REVIEW,
							},
							{
								label: ContractStatus.UNDER_APPROVAL,
								value: ContractStatus.UNDER_APPROVAL,
							},
							{
								label: ContractStatus.CHANGE_REJECTED,
								value: ContractStatus.CHANGE_REJECTED,
							},
							{
								label: ContractStatus.CHANGE_APPROVED,
								value: ContractStatus.CHANGE_APPROVED,
							},
						]}
						label={""}
						name={""}
						onChange={noop}
						value={undefined}
					/>
				}
			/>
			<FiltersField
				name={filtersLabels.country_id.key}
				label={filtersLabels.country_id.label}
				defaultOperation={
					retrieveOperationFromFieldName(
						"country_id",
						defaultFilters
					) || Operations.IN
				}
				defaultFieldValue={
					retrieveValueFromFieldName("country_id", defaultFilters) ||
					[]
				}
				allowedOperations={[Operations.IN, Operations.NOT_IN]}
				selector={(data: string) => data}
				Input={
					<FluidSelectMultiple
						isLabelStatic
						items={countryOptions}
						label={""}
						name={""}
						onChange={noop}
						value={[]}
					/>
				}
			/>
			<FiltersField
				name={filtersLabels.book_id.key}
				label={filtersLabels.book_id.label}
				defaultOperation={
					retrieveOperationFromFieldName("book_id", defaultFilters) ||
					Operations.IN
				}
				defaultFieldValue={
					retrieveValueFromFieldName("book_id", defaultFilters) || []
				}
				allowedOperations={[Operations.IN, Operations.NOT_IN]}
				selector={(data: string) => data}
				Input={
					<FluidSelectMultiple
						isLabelStatic
						items={booksOptions}
						label={""}
						name={""}
						onChange={noop}
						value={[]}
					/>
				}
			/>
			<FiltersField
				name={filtersLabels.contract_type_id.key}
				label={filtersLabels.contract_type_id.label}
				defaultOperation={
					retrieveOperationFromFieldName(
						"contract_type_id",
						defaultFilters
					) || Operations.IN
				}
				defaultFieldValue={
					retrieveValueFromFieldName(
						"contract_type_id",
						defaultFilters
					) || []
				}
				allowedOperations={[Operations.IN, Operations.NOT_IN]}
				selector={(data: string) => data}
				Input={
					<FluidSelectMultiple
						isLabelStatic
						items={contractTypesOptions}
						label={""}
						name={""}
						value={[]}
					/>
				}
			/>
			<FiltersField
				name={filtersLabels.contractperiods__dealconfigs__method.key}
				label={filtersLabels.contractperiods__dealconfigs__method.label}
				defaultOperation={
					retrieveOperationFromFieldName(
						"contractperiods__dealconfigs__method",
						defaultFilters
					) || Operations.IN
				}
				defaultFieldValue={
					retrieveValueFromFieldName(
						"contractperiods__dealconfigs__method",
						defaultFilters
					) || []
				}
				allowedOperations={[Operations.IN]}
				selector={(data: string) => data}
				Input={
					<FluidSelectMultiple
						isLabelStatic
						items={Object.values(DealConfigMethods).map(
							(value) => ({
								value: value as string,
								label: value as string,
							})
						)}
						label={""}
						name={""}
						onChange={noop}
						value={[]}
					/>
				}
			/>
			<FiltersField
				name={filtersLabels.start_date.key}
				label={filtersLabels.start_date.label}
				defaultOperation={
					retrieveOperationFromFieldName(
						"start_date",
						defaultFilters
					) || Operations.EQ
				}
				defaultFieldValue={
					retrieveValueFromFieldName("start_date", defaultFilters) ||
					""
				}
				allowedOperations={[
					Operations.EQ,
					Operations.NOT,
					Operations.LE,
					Operations.GE,
				]}
				Input={
					<FluidDatePicker
						onChange={noop}
						name={""}
						label={""}
						value={undefined}
					/>
				}
			/>
			<FiltersField
				name={filtersLabels.end_date.key}
				label={filtersLabels.end_date.label}
				defaultOperation={
					retrieveOperationFromFieldName(
						"end_date",
						defaultFilters
					) || Operations.EQ
				}
				defaultFieldValue={
					retrieveValueFromFieldName("end_date", defaultFilters) || ""
				}
				allowedOperations={[
					Operations.EQ,
					Operations.NOT,
					Operations.LE,
					Operations.GE,
				]}
				Input={
					<FluidDatePicker
						onChange={noop}
						name={""}
						label={""}
						value={undefined}
					/>
				}
			/>
			<FiltersField
				name={filtersLabels.signature_date.key}
				label={filtersLabels.signature_date.label}
				defaultOperation={
					retrieveOperationFromFieldName(
						"signature_date",
						defaultFilters
					) || Operations.EQ
				}
				defaultFieldValue={
					retrieveValueFromFieldName(
						"signature_date",
						defaultFilters
					) || ""
				}
				allowedOperations={[
					Operations.EQ,
					Operations.NOT,
					Operations.LE,
					Operations.GE,
				]}
				Input={
					<FluidDatePicker
						onChange={noop}
						name={""}
						label={""}
						value={undefined}
					/>
				}
			/>
			<FiltersField
				name={filtersLabels.agreement_type_id.key}
				label={filtersLabels.agreement_type_id.label}
				defaultOperation={
					retrieveOperationFromFieldName(
						"agreement_type_id",
						defaultFilters
					) || Operations.IN
				}
				defaultFieldValue={
					retrieveValueFromFieldName(
						"agreement_type_id",
						defaultFilters
					) || []
				}
				allowedOperations={[Operations.IN, Operations.NOT_IN]}
				selector={(data: string) => data}
				Input={
					<FluidSelectMultiple
						isLabelStatic
						items={agreementTypesOptions}
						label={""}
						name={""}
						onChange={noop}
						value={[]}
					/>
				}
			/>
			<FiltersField
				name={
					filtersLabels
						.contractperiods__site_contractperiods__site__name.key
				}
				label={
					filtersLabels
						.contractperiods__site_contractperiods__site__name.label
				}
				defaultOperation={
					retrieveOperationFromFieldName(
						"contractperiods__site_contractperiods__site__name",
						defaultFilters
					) || Operations.ILIKE
				}
				defaultFieldValue={
					retrieveValueFromFieldName(
						"contractperiods__site_contractperiods__site__name",
						defaultFilters
					) || []
				}
				allowedOperations={[Operations.ILIKE]}
				Input={
					<FluidTextField
						label={""}
						name={""}
						onChange={noop}
						value={""}
					/>
				}
			/>
		</FiltersDrawer>
	);
};

export default ContractPageFilters;
