export function isFalsy(value: any) {
	return (
		value === null ||
		value === undefined ||
		value === 0 ||
		(typeof value === "object" && Object.keys(value).length === 0)
	);
}

export function isNull(value: any) {
	return value === null || value === undefined;
}
