import { Box, SxProps, Theme } from "@mui/material";
import { PricingTableRowToolTip } from "./PricingTableRowTooltip";
import { PricingTableRowProps } from "./PricingTableRowProps";

export default function PricingTableRowBox(props: PricingTableRowProps) {
	const { value, sx } = props;
	const boxSx: SxProps<Theme> = {
		...sx,
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
	};
	return (
		<Box sx={boxSx}>
			<PricingTableRowToolTip value={value ?? ""} />
		</Box>
	);
}
