import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import Modal from "../Modal";
import { PrimaryButton } from "../CustomButton";
import { warningPrimary } from "../../../core/theme";

import type { ReactNode } from "react";

export interface ConfirmationModalProps {
	title: ReactNode;
	text?: string | ReactNode;
	isOpen: boolean;
	icon?: ReactNode;
	onClose: () => void;
	onConfirm: () => void;
	unConfirm: () => void;
}

export const ConfirmationModal = ({
	title,
	text = "",
	icon,
	isOpen,
	onClose,
	onConfirm,
	unConfirm,
}: ConfirmationModalProps) => {
	return (
		<Modal
			title={title}
			icon={icon}
			sx={{
				padding: "0px 24px",
				width: "618px",
			}}
			actions={
				<>
					<PrimaryButton
						sx={{
							backgroundColor: "#fff",
							color: "black",
							border: "1px solid black",
							width: "106px",

							"&:hover": {
								backgroundColor: "#9595965e",
								color: "black !important",
							},
						}}
						text="Cancel"
						onClick={onClose}
					/>
					<PrimaryButton
						sx={{
							backgroundColor: "#052B5014",
							color: "black",

							"&:hover": {
								backgroundColor: "#052b503d",
								color: "black !important",
							},
						}}
						text="Discard changes"
						onClick={unConfirm}
					/>
					<PrimaryButton
						sx={{
							backgroundColor: warningPrimary,

							"&:hover": {
								backgroundColor: "#ff4d4d",
							},
						}}
						text="Save changes"
						onClick={onConfirm}
					>
						<SaveOutlinedIcon />
					</PrimaryButton>
				</>
			}
			open={isOpen}
			onClose={onClose}
		>
			{text}
		</Modal>
	);
};
