import { Toggle } from "rsuite";
import { getIn } from "formik";
import { FluidNumber } from "../../../../../common/components/FluidNumberField";
import { FluidRadioGroup } from "../../../../../common/components/FluidRadioGroup";
import FluidSelect from "../../../../../common/components/FluidSelect";
import { If } from "../../../../../common/components/If";
import { Spacer } from "../../../../../common/components/Spacer";
import {
	ClickPeriodFrequency,
	ClickabilityType,
	ClickLagType,
	LagBusinessDayConvention,
	LagCalendar,
} from "../../../../../requests_geco/contractsApi/contractsApi.types";

export interface ClickabilityFormProps {
	errors: any;
	values: any;
	setFieldValue: (field: string, value: any) => void;
	handleChange: (e: React.ChangeEvent<any> | boolean) => void;
}

export const ClickabilityFields = ({
	errors,
	values,
	setFieldValue,
	handleChange,
}: ClickabilityFormProps) => {
	return (
		<>
			<FluidRadioGroup
				orientation="row"
				description="Period of the click"
				name={"click_period"}
				errorMessage={getIn(errors, "click_period")}
				value={values.click_period as string}
				title={"Click Period"}
				onChange={(value: unknown | string) =>
					setFieldValue("click_period", value)
				}
				options={[
					{
						label: "Monthly",
						value: ClickPeriodFrequency.MONTH,
					},
					{
						label: "Quarterly",
						value: ClickPeriodFrequency.QUARTER,
					},
					{
						label: "Yearly",
						value: ClickPeriodFrequency.CAL_YEAR,
					},
					{
						label: "Default trade date",
						value: ClickPeriodFrequency.DEFAULT,
					},
				]}
			/>
			<Spacer gap={24} />
			<FluidRadioGroup
				orientation="row"
				description="Type of click configuration"
				name={"click_type"}
				errorMessage={getIn(errors, "click_type")}
				value={values.click_type as string}
				title={"Click Type"}
				onChange={(value: unknown | string) =>
					setFieldValue("click_type", value)
				}
				options={[
					{
						label: ClickabilityType.OTC,
						value: ClickabilityType.OTC,
					},
					{
						label: ClickabilityType.TAS,
						value: ClickabilityType.TAS,
					},
					{
						label: ClickabilityType.FORMULA,
						value: ClickabilityType.FORMULA,
					},
				]}
			/>
			<Spacer gap={24} />
			<FluidRadioGroup
				description="Party holding the rights to execute the click"
				name={"right_to_seller"}
				errorMessage={getIn(errors, "right_to_seller")}
				value={values.right_to_seller}
				title={"Mandatary"}
				onChange={(value: unknown | string) =>
					setFieldValue("right_to_seller", value)
				}
				options={[
					{
						label: "Buyer",
						// true
						value: "0",
					},
					{
						label: "Seller",
						// false
						value: "1",
					},
				]}
			/>
			<Spacer gap={24} />
			<FluidNumber
				onChange={handleChange}
				errorMessage={getIn(errors, "max_capacity")}
				name={"max_capacity"}
				title={"Max Capacity"}
				description="Max base production capacity."
				value={values.max_capacity as number}
			/>
			<Spacer gap={24} />
			<FluidNumber
				onChange={handleChange}
				name={"min_capacity"}
				errorMessage={getIn(errors, "min_capacity")}
				title={"Min Capacity"}
				description="Min base production capacity"
				value={values.min_capacity as number}
			/>
			<Spacer gap={24} />
			<FluidNumber
				onChange={handleChange}
				name={"max_click_percentage"}
				title={"Max Pcr Clickable"}
				errorMessage={getIn(errors, "max_click_percentage")}
				description="The maximum amount clickable of the installed capacity in 1 click"
				value={values.max_click_percentage as number}
			/>
			<Spacer gap={24} />
			<FluidNumber
				onChange={handleChange}
				name={"min_click_percentage"}
				title={"Min Pcr Clickable"}
				errorMessage={getIn(errors, "min_click_percentage")}
				description="The minimum amount clickable of the installed capacity in 1 click"
				value={values.min_click_percentage as number}
			/>
			<Spacer gap={24} />
			<FluidNumber
				onChange={handleChange}
				name={"max_nb_click"}
				title={"Max N Clicks"}
				description="Maximum clicks that can be made"
				value={values.max_nb_click as number}
				errorMessage={getIn(errors, "max_nb_click")}
			/>
			<Spacer gap={24} />
			<FluidNumber
				onChange={handleChange}
				name={"max_nb_declick"}
				title={"Max N Declicks"}
				description="Maximum declicks that can be made"
				value={values?.max_nb_declick as number}
				errorMessage={getIn(errors, "max_nb_declick")}
			/>
			<Spacer gap={24} />
			<Toggle
				// @ts-ignore
				name={"allow_full_declick"}
				label={"Allow Full Declick"}
				description="Is a full declick allowed"
				onChange={handleChange}
				isTrue={values.allow_full_declick}
			/>
			<Spacer gap={24} />
			<FluidNumber
				onChange={handleChange}
				name={"alpha"}
				title={"Factor"}
				description="Alpha parameter; If empty the Alpha from the contractperiod will be used"
				value={values.alpha as number}
				errorMessage={getIn(errors, "alpha")}
			/>
			<Spacer gap={24} />
			<FluidNumber
				onChange={handleChange}
				name={"beta"}
				title={"Spread"}
				description="Beta parameter; If empty the Beta from the contractperiod will be used"
				value={values.beta as number}
				errorMessage={getIn(errors, "beta")}
			/>
			<Spacer gap={24} />
			<If
				condition={
					!!values.aggregation_fee || values.aggregation_fee == 0
				}
			>
				<FluidNumber
					onChange={handleChange}
					name={"aggregation_fee"}
					title={"Aggregation Fee"}
					value={values.aggregation_fee as number}
					errorMessage={getIn(errors, "aggregation_fee")}
				/>
				<Spacer gap={24} />
			</If>
			<If condition={!!values.service_fee || values.service_fee == 0}>
				<FluidNumber
					onChange={handleChange}
					name={"service_fee"}
					title={"Service Fee"}
					value={values.service_fee as number}
					errorMessage={getIn(errors, "service_fee")}
				/>
				<Spacer gap={24} />
			</If>
			<FluidNumber
				onChange={handleChange}
				name={"last_click_lag"}
				title={"Last Click Lag"}
				description="Mandatory Time period between clicks"
				value={values.last_click_lag as number}
				errorMessage={getIn(errors, "last_click_lag")}
			/>
			<Spacer gap={24} />
			<FluidSelect
				isLabelStatic
				items={[
					{
						value: ClickLagType.PREVIOUS_MONTH,
						label: ClickLagType.PREVIOUS_MONTH,
					},
				]}
				label={"Click Lag Type"}
				name={"click_lag_type"}
				onChange={(value: unknown | ClickLagType) =>
					setFieldValue("click_lag_type", value)
				}
				value={values.click_lag_type}
				hasError={getIn(errors, "click_lag_type")}
				errorMessage={getIn(errors, "click_lag_type")}
			/>
			<Spacer gap={24} />
			<FluidRadioGroup
				name={"lag_business_day_convention"}
				value={values.lag_business_day_convention as string}
				errorMessage={getIn(errors, "lag_business_day_convention")}
				title={"Lag Business Day Convention"}
				onChange={(value: unknown | string) =>
					setFieldValue("lag_business_day_convention", value)
				}
				options={[
					{
						label: LagBusinessDayConvention.Following,
						value: LagBusinessDayConvention.Following,
					},
					{
						label: LagBusinessDayConvention.Preceding,
						value: LagBusinessDayConvention.Preceding,
					},
				]}
			/>
			<Spacer gap={24} />

			<FluidSelect
				isLabelStatic
				items={[
					{
						value: LagCalendar.EEX,
						label: LagCalendar.EEX,
					},
				]}
				label={"Lag Calendar"}
				name={"lag_calendar"}
				onChange={(value: unknown | LagCalendar) =>
					setFieldValue("lag_calendar", value)
				}
				errorMessage={getIn(errors, "lag_calendar")}
				value={values.lag_calendar}
				hasError={getIn(errors, "lag_calendar")}
			/>
		</>
	);
};
