import { useCallback, useState } from "react";
import { Formik, FormikErrors } from "formik";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { styled } from "@mui/material/styles";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
	Box,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from "@mui/material";
import { FluidNumber } from "../../../../common/components/FluidNumberField";
import {
	Fee,
	FeeName,
	SettlementType,
} from "../../../../requests_geco/contractsApi/contractsApi.types";
import { FluidButton } from "../../../../common/components/FluidButton";
import _, { noop } from "lodash";
import { PrimaryButton } from "../../../../common/components/CustomButton";
import { feeValidator } from "../../formik/editSettlementValidationValidator";
import Collapse from "../../../../common/components/Collapse";
import Button from "@mui/material/Button";
import FluidSelect from "../../../../common/components/FluidSelect";
import { getSyntheticEvent } from "../../../../common/utils/getSyntheticEvent";

const AddFeeButton = styled(Button)({
	textTransform: "none",
});

const style = {
	addFeetContainer: {
		display: "flex",
		flexDirection: "column",
		gap: "8px",
	},

	container: {
		display: "flex",
		flexDirection: "row",
		gap: "24px",
		alignItems: "flex-end",
		flexWrap: "wrap",
		feeWrapper: {
			width: 162,
			display: "flex",
			alignItems: "flex-end",
			gap: "8px",
			icon: {
				cursor: "pointer",
				marginBottom: "5px",
				fontSize: "28px",
			},
		},
	},
};

function AddFeeModal({
	onSubmit,
	isOpen,
	handleClose,
}: {
	onSubmit: ({ name, value }: { name: ""; value: number }) => void;
	isOpen: boolean;
	handleClose: () => void;
}) {
	return (
		<Dialog maxWidth="lg" onClose={handleClose} open={isOpen}>
			<DialogTitle>{"New Fee"}</DialogTitle>
			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={feeValidator}
				enableReinitialize
				initialValues={{
					name: "",
					value: 0,
				}}
				onSubmit={onSubmit}
			>
				{({ handleChange, handleSubmit, values, errors }) => (
					<form
						onSubmit={(formValue) => {
							handleSubmit(formValue);
							handleClose();
						}}
					>
						<DialogContent>
							<Box sx={style.addFeetContainer}>
								<FluidSelect
									sx={{ width: "80%" }}
									items={[
										{
											value: FeeName.AGGREGATION_FEE,
											label: FeeName.AGGREGATION_FEE,
										},
										{
											value: FeeName.SERVICE_FEE,
											label: FeeName.SERVICE_FEE,
										},
										{
											value: FeeName.TRANSFORMATION_FEE,
											label: FeeName.TRANSFORMATION_FEE,
										},
									]}
									onChange={(selected_item: unknown) =>
										handleChange(
											getSyntheticEvent(
												"name",
												selected_item
											)
										)
									}
									value={values.name}
									name={"name"}
									label={"Name"}
									errorMessage={errors.name}
								/>
								<FluidNumber
									sx={{ width: "80%" }}
									onChange={handleChange}
									name={"value"}
									title={"Value"}
									value={values.value}
									errorMessage={errors.value}
								/>
							</Box>
						</DialogContent>

						<DialogActions>
							<PrimaryButton
								onClick={handleClose}
								text="Cancel"
								type="button"
								color="secondary"
							/>
							<FluidButton
								type="submit"
								icon={"add_circle_outline"}
								label={"Add"}
								onClick={noop}
							/>
						</DialogActions>
					</form>
				)}
			</Formik>
		</Dialog>
	);
}

export interface FeesComponentProps {
	title: string;
	values: SettlementType;
	errors: FormikErrors<SettlementType>;
	feeFieldName: string;
	setFieldValue: (
		field: string,
		value: any,
		shouldValidate?: boolean
	) => void;
	handleChange: {
		(e: React.ChangeEvent<any>): void;
		<T = string | React.ChangeEvent<any>>(
			field: T
		): T extends React.ChangeEvent<any>
			? void
			: (e: string | React.ChangeEvent<any>) => void;
	};
}

export const FeesComponent = ({
	values,
	errors,
	title,
	feeFieldName,
	handleChange,
	setFieldValue,
}: FeesComponentProps) => {
	const [isModalOpen, setModalOpen] = useState(false);

	const onAddNewFee = useCallback(
		(entry: { name: string; value: number }) => {
			const newPosition = (_.get(values, feeFieldName) || []).length;
			setFieldValue(`${feeFieldName}[${newPosition}]`, entry);
		},
		[_.get(values, feeFieldName)]
	);

	const onRemoveFee = useCallback(
		(index: number) => {
			const newFeeList = (_.get(values, feeFieldName) || []).filter(
				(_fee: any, i: number) => i !== index
			);
			setFieldValue(feeFieldName, newFeeList);
		},
		[_.get(values, feeFieldName)]
	);

	return (
		<Box>
			<Collapse
				open
				title={title}
				action={
					<Box sx={{ marginLeft: "auto" }}>
						<AddFeeButton
							variant="text"
							onClick={() => setModalOpen(true)}
							startIcon={<AddCircleOutlineIcon />}
						>
							Add
						</AddFeeButton>
					</Box>
				}
			>
				<Box sx={style.container}>
					{(_.get(values, feeFieldName) || []).map(
						(fee: Fee, feeIndex: number) => (
							<Box key={feeIndex} sx={style.container.feeWrapper}>
								<FluidNumber
									onChange={handleChange}
									value={fee?.value}
									name={`${feeFieldName}[${feeIndex}].value`}
									title={fee?.name}
									//@ts-ignore
									errorMessage={_.get(
										errors,
										`${feeFieldName}[${feeIndex}].value`
									)}
								/>
								<DeleteOutlineIcon
									color="primary"
									sx={style.container.feeWrapper.icon}
									onClick={() => onRemoveFee(feeIndex)}
								/>
							</Box>
						)
					)}
				</Box>
			</Collapse>

			<AddFeeModal
				onSubmit={onAddNewFee}
				isOpen={isModalOpen}
				handleClose={() => setModalOpen(false)}
			/>
		</Box>
	);
};
