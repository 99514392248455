import {
	Grid,
	Theme,
	Box,
	Typography,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Stack,
	FormGroup,
	Switch,
	Tooltip,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddCircleIconOutline from "@mui/icons-material/AddCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { borderNeutralSecondary, lightGrey } from "../../core/theme";
import { Formik } from "formik";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { PrimaryButton } from "../../common/components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { TenderCreationModel, tenderSchema } from "./tenderForm.schema";
import { SxProps } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import en from "date-fns/locale/en-GB";
import CustomDatePicker from "../../common/components/CustomDatePicker";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
	selectTender,
	selectAccounts,
	selectAccountsLoader,
	selectGlobalSites,
	selectCreateTenderWarning,
	selectSiteKnownStatusLoading,
	selectSiteKnownStatusError,
	selectLoader,
} from "./tenderForm.selector";
import {
	createTender,
	updateTender,
	getAccounts,
	addListNewSitesToTender,
	deleteMultipleSitesOnTender,
} from "./tenderForm.thunk";
import CustomAutocomplete from "../../common/components/CustomAutocomplete";
import CustomInput from "../../common/components/CustomInput";
import {
	createTenderWarning,
	getTenderSuccess,
	initTimeseries,
	resetTender,
	setPortfoliosSites,
	updateAccountsLoader,
} from "./tenderForm.slice";
import PortfolioModal from "./components/PortfolioModal";
import { DropZoneInput } from "../../common/components/DropZoneInput";
import { useAppDispatch } from "../../common/hooks/default";
import { mapTenderStateToEndpointBody } from "./components/tenderForm.mappers.submit";
import { Tender } from "../tender_page/tender.module";
import { initialTenderValues } from "./tenderForm.constant";
import { Portfolio, Site, isPortfolio } from "../sites/sites.module";
import SiteAndPortfolioTable from "../../common/components/SiteAndPortfolioTable";
import { selectSites, selectLoading } from "../sites/sites.selector";
import { getTprSites } from "../sites/sites.thunk";
import { OverlayLoader } from "../../common/components/OverlayLoader";
import Prompt from "../../common/components/Prompt";
import PageTitle from "../../common/components/PageTitle/PageTitle";
import PageContent from "../../common/components/PageContent";
import { addWeeks, format } from "date-fns";
import useIsAuthorized from "../../common/hooks/useIsAuthorized";
import {
	isUserAbleRefreshCluster,
	isUserAbleUpdateFilesOnTender,
	isUserAbleUpdateOffer,
} from "../../common/authorization/tender.authorization";
import { If } from "../../common/components/If";
import AfterCreationModal from "./components/AfterCreationModal";
import { mergeSitesAndPortfolios } from "../pricing_request/utils/helpers.utils";
import SiteSelectionFromGemforce from "../../common/components/GemforceSiteSelection";
import { initSites } from "../sites/siteSlice";
import * as _ from "lodash";
import { PortfolioCreation } from "./tenderForm.module";
import AlertDialogModal from "../../common/components/AlertDialogModal";
import RequestQuoteIcon from "@mui/icons-material/RequestQuoteOutlined";
import wsPublisher$ from "../../core/wsPublisher";
import { WsEventTypes } from "../../common/constants/wsEvents";
import { WsMessage } from "../../core/socketProvider";
import { getPathWithParameters, PATH } from "../../router-path";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import { addNonWorkingDays } from "../../common/utils/bankHolidays";
import { useGoBack } from "../../common/utils/useGoback";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { TimeseriesTable } from "./components/TimeseriesTable";
import { RootState } from "../../core/rootReducers";
import { HistoricalDataFilePreview } from "../../common/components/HistoricalDataFilePreview";

const style: { [key: string]: SxProps<Theme> } = {
	container: {
		minWidth: "1280px",
		bgcolor: lightGrey,
	},
	titleContainer: {
		width: "100%",
		alignItems: "center",
		marginBottom: "45px",
	},
	title: {
		marginBottom: "30px",
		flexGrow: "2",
		margin: 0,
		fontSize: 36,
	},
	subTitle: {
		marginBottom: "30px",
		fontSize: 24,
	},
	tenderInput: {
		marginBottom: "40px",
		"& p": {
			backgroundColor: lightGrey,
		},
	},
	radioGroup: {
		marginBottom: "40px",
	},
	radioGroupLabel: {
		fontSize: "14px",
	},
	infoFields: {
		display: "flex",
		flexDirection: "column",
	},
};

interface TenderFromProps {
	mode: "new" | "edit" | "clone";
}

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

function CustomTabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography component={"div"}>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

const TenderForm: React.FC<TenderFromProps> = (props: TenderFromProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const goBack = useGoBack();

	const { tenderId } = useParams();
	const tender = useSelector(selectTender);
	const updatedTenderWarning = useSelector(selectCreateTenderWarning);
	const accounts = useSelector(selectAccounts);
	const accountLoader = useSelector(selectAccountsLoader);
	const globalSites = useSelector(selectGlobalSites);
	const availableSites = useSelector(selectSites);

	const siteKnownStatusAreLoading = useSelector(selectSiteKnownStatusLoading);
	const siteKnownStatusError = useSelector(selectSiteKnownStatusError);

	const siteLoader = useSelector(selectLoading);

	const tenderLoader = useSelector((state: RootState) =>
		selectLoader(state, "tender")
	);

	const isUserAbleToUpdateAllFields = useIsAuthorized<Tender>(
		isUserAbleUpdateOffer,
		tender,
		!!(props.mode !== "edit")
	);

	const isUserAbleToRefreshCluster = useIsAuthorized<Tender>(
		isUserAbleRefreshCluster,
		tender,
		!!(props.mode !== "edit")
	);

	const isUserAbleToUploadFiles = useIsAuthorized<Tender>(
		isUserAbleUpdateFilesOnTender,
		tender
	);

	const [openPortfolioModal, setOpenPortfolioModal] = useState(false);

	const [formState, setFormState] =
		useState<TenderCreationModel>(initialTenderValues);

	const [openSiteSelection, setOpenSiteSelection] = useState(false);
	const [selectedPortfolioId, setSelectedPortfolioId] = useState<number>();
	const [createdTenderId, setCreatedTenderId] = useState(0);

	const sortedPortfolioAndSites = useMemo(
		() => mergeSitesAndPortfolios(tender),
		[tender]
	);

	const [sitesUpdateMessages, setSitesUpdateMessages] = useState<
		WsMessage<any>[]
	>([]);

	const [activeTab, setActiveTab] = useState(0);
	const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
	};

	const onProxyMessageUpdated = useCallback(
		(messages: WsMessage<any>[]) => {
			setSitesUpdateMessages(messages);
		},
		[dispatch]
	);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[
				WsEventTypes.PROXY_IN_PROGRESS,
				WsEventTypes.PROXY_SUCCEEDED,
				WsEventTypes.PROXY_FAILED,
			],
			onProxyMessageUpdated
		);
	}, [onProxyMessageUpdated]);

	const onTenderUpdated = useCallback(
		(messages: WsMessage<any>[]) => {
			messages.forEach((message: { data: Tender }) => {
				if (
					message?.data &&
					tenderId &&
					message.data.id === Number(tenderId)
				) {
					dispatch(getTenderSuccess({ tender: message.data }));
				}
			});
		},
		[dispatch]
	);

	useEffect(() => {
		return wsPublisher$.subscribe(
			[WsEventTypes.TENDER_UPDATED],
			onTenderUpdated
		);
	}, [onTenderUpdated]);

	const isUpdateDisabled = useMemo(() => {
		return (
			props.mode === "edit" &&
			!(isUserAbleToUpdateAllFields || isUserAbleToUploadFiles)
		);
	}, [isUserAbleToUpdateAllFields, props.mode, isUserAbleToUploadFiles]);

	const sitesFromPortfolio = useMemo(() => {
		if (selectedPortfolioId) {
			const sites = (tender?.portfolios || []).filter(
				(portfolio: Site | Portfolio) =>
					isPortfolio(portfolio) &&
					portfolio.id === selectedPortfolioId
			);

			if (sites?.length > 0) {
				return sites[0]?.sites;
			}
		}
		return globalSites;
	}, [tender, globalSites, selectedPortfolioId]);

	const getTprSite = (
		search: string = "",
		onlyPricingReady: boolean = true,
		page: number = 1,
		sort_by: string = "",
		order: string = ""
	) => {
		dispatch(getTprSites(search, onlyPricingReady, page, sort_by, order));
	};

	const handleCloseSiteSelection = (
		sites: Site[],
		shouldRefreshAfterClear: boolean = true,
		previousSites: Site[] = []
	) => {
		const sitesToRemove = previousSites.filter(
			(site: Site) => !sites.includes(site)
		);
		dispatch(deleteMultipleSitesOnTender(sitesToRemove));
		dispatch(addListNewSitesToTender(sites));
		setOpenSiteSelection(false);
		if (!shouldRefreshAfterClear) {
			dispatch(initSites());
		}
	};

	const handleCloseSiteSelectionOnPortfolio = (
		sites: Site[],
		shouldRefreshAfterClear: boolean = true
	) => {
		dispatch(
			setPortfoliosSites({
				portfolioId: selectedPortfolioId as number,
				sites,
			})
		);
		setSelectedPortfolioId(undefined);
		setOpenSiteSelection(false);
		if (!shouldRefreshAfterClear) {
			dispatch(initSites());
		}
	};

	useEffect(() => {
		dispatch(resetTender());
		setSelectedPortfolioId(undefined);
	}, []);

	const [previousSites, setPreviousSites] = useState<number[] | null>(null);
	const [currentSites, setCurrentSites] = useState<number[] | null>(null);

	useEffect(() => {
		if (
			tender &&
			(props.mode === "edit" || props.mode === "clone") &&
			Number(tenderId) === Number(tender.id)
		) {
			const tenderState = { ...initialTenderValues };
			tenderState.account = {
				id: tender.account.id,
				name: tender.account.name,
				external_id: tender.account.external_id,
			};

			if (props.mode === "edit") {
				tenderState.tenderName = tender.name;
				tenderState.deadline = new Date(tender.due_date);
				tenderState.validityDate = new Date(tender.validity_date);
			}

			tenderState.direction = tender.direction;
			tenderState.transactionType = tender.transaction_type;
			tenderState.historicalData = tender.historical_data;
			tenderState.pricing_stage = tender.pricing_stage || "INDICATIVE";
			tenderState.has_negative_price_clause =
				tender.has_negative_price_clause;
			tenderState.is_balancing_responsible_party =
				tender.is_balancing_responsible_party;
			tenderState.bypassWarning = false;
			tenderState.realized_data_will_be_added =
				tender.realized_data_will_be_added ?? false;

			setFormState(tenderState);
			if (previousSites === null) {
				setPreviousSites((tender?.sites || []).map((s) => s.id));
			}
		}

		setCurrentSites(tender?.sites ? tender.sites.map((s) => s.id) : null);

		// @ts-ignore
	}, [tender, props.mode]);

	useEffect(() => {
		return () => dispatch(initTimeseries()) as any;
	}, []);

	const fetchAccounts = (search: string) => {
		dispatch(updateAccountsLoader(true));
		dispatch(getAccounts(search));
	};

	const actionTitle = () => {
		if (props.mode === "new") {
			return "Add Tender";
		}
		if (props.mode === "edit") {
			return "Edit Tender";
		} else {
			return "Clone Tender";
		}
	};

	if (tenderLoader) {
		return <OverlayLoader />;
	}

	return (
		<>
			<PageTitle
				label={actionTitle()}
				leftSide={
					<>
						<PrimaryButton
							text="Back"
							type="submit"
							color="info"
							sx={{
								width: 106,
								color: "#171D21",
							}}
							onClick={() =>
								goBack({
									fallback: getPathWithParameters(
										PATH.TENDER_PRICING_RUN,
										{ tenderId }
									),
								})
							}
						>
							<ChevronLeftIcon />
						</PrimaryButton>
						{props.mode === "edit" && (
							<PrimaryButton
								text="Pricing list"
								type="button"
								href={getPathWithParameters(
									PATH.TENDER_PRICING_RUN,
									{ tenderId }
								)}
								onClick={(e) => {
									e.preventDefault();
									navigate(
										getPathWithParameters(
											PATH.TENDER_PRICING_RUN,
											{ tenderId }
										)
									);
									return false;
								}}
								color="info"
								sx={{
									width: 130,
									color: "#171D21",
								}}
							>
								<RequestQuoteIcon />
							</PrimaryButton>
						)}
					</>
				}
				rightSide={
					props.mode === "edit" ? (
						<Box display="flex" justifyContent="flex-end">
							<Typography component="span" marginLeft={3}>
								Originator:&nbsp;
							</Typography>
							<Typography component="span">
								{tender?.originator?.first_name}{" "}
								{tender?.originator?.last_name}
							</Typography>
							<Typography component="span" marginLeft={3}>
								Trader:&nbsp;
							</Typography>
							<Typography component="span">
								{tender?.trader?.first_name}{" "}
								{tender?.trader?.last_name}
							</Typography>
						</Box>
					) : (
						<></>
					)
				}
			/>
			<PageContent>
				<Formik
					initialValues={formState}
					validationSchema={tenderSchema}
					enableReinitialize={true}
					onSubmit={async (values, { setSubmitting }) => {
						const newTender = mapTenderStateToEndpointBody(
							tender as Tender,
							values
						);

						if (props.mode === "clone" && newTender?.portfolios) {
							newTender.portfolios = newTender?.portfolios?.map(
								(p) =>
									_.omit<PortfolioCreation>(p, [
										"id",
									]) as Portfolio
							);
						}

						let tenderResponse = null;
						let success = true;
						if (props.mode === "edit") {
							success = await dispatch(
								updateTender(
									Number(tenderId),
									newTender,
									values.bypassWarning
								)
							);
						} else {
							tenderResponse = await dispatch(
								createTender(newTender)
							);
						}

						setSubmitting(false);
						if (success) {
							setPreviousSites(null);
							if (props.mode === "clone") {
								navigate(
									getPathWithParameters(PATH.EDIT_TENDER, {
										tenderId: tenderResponse?.id,
									})
								);
							}
							if (props.mode === "new") {
								await dispatch(resetTender());
								setCreatedTenderId(tenderResponse?.id || 0);
							}
						}
					}}
				>
					{({
						isSubmitting,
						handleChange,
						handleSubmit,
						values,
						errors,
						touched,
						setFieldValue,
						dirty,
						setSubmitting,
					}) => {
						return (
							<Box sx={style.container}>
								<Prompt
									message="You will lose all changes on the tender. Proceed ?"
									block={
										(dirty ||
											!_.isEqual(
												currentSites ?? [],
												previousSites ?? []
											)) &&
										!isSubmitting &&
										!isUpdateDisabled
									}
								></Prompt>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
								>
									<Grid item xs justifyContent="flex-start">
										<form
											id="tender-form"
											noValidate
											onSubmit={handleSubmit}
										>
											<Stack
												display="flex"
												textAlign="start"
												sx={{
													borderRight: 1,
													borderColor:
														borderNeutralSecondary,
													paddingRight: "30px",
												}}
											>
												<Typography
													component="div"
													sx={style.subTitle}
												>
													Infos
												</Typography>
												<CustomAutocomplete
													disabled={
														!isUserAbleToUpdateAllFields
													}
													fetchData={fetchAccounts}
													data={accounts}
													label="Account"
													name="account"
													loading={accountLoader}
													setFieldValue={
														setFieldValue
													}
													value={values.account}
													onChange={handleChange}
													error={
														!!errors.account &&
														touched.account
													}
													helperText={
														!!errors.account &&
														touched.account
															? (
																	errors.account as any
															  ).name
															: ""
													}
												/>
												<FormGroup
													sx={{
														marginBottom: "20px",
													}}
												>
													<FormControlLabel
														control={
															<Switch
																disabled={
																	props.mode ===
																	"edit"
																}
																name="realized_data_will_be_added"
																checked={
																	values.realized_data_will_be_added
																}
																onChange={(
																	e
																) => {
																	// tomorrow + 5 or 6. So simply by with today + 6 or 7
																	const deadlineOffset =
																		e.target
																			.checked
																			? 7
																			: 6;
																	const deadline =
																		addNonWorkingDays(
																			new Date(),
																			deadlineOffset
																		);
																	setFieldValue(
																		"deadline",
																		deadline
																	);
																	handleChange(
																		e
																	);
																}}
															/>
														}
														label={
															<Stack
																direction="row"
																spacing={1}
															>
																<span>
																	Realized
																	data will be
																	added
																</span>
																<Tooltip title="1 working day will be added to the default deadline if realized data is provided">
																	<HelpOutlineOutlinedIcon />
																</Tooltip>
															</Stack>
														}
													/>
												</FormGroup>
												<LocalizationProvider
													dateAdapter={AdapterDateFns}
													adapterLocale={en}
												>
													<CustomDatePicker
														disabled={
															!isUserAbleToUpdateAllFields
														}
														name="deadline"
														disablePast
														sx={style.tenderInput}
														slotProps={{
															textField: {
																required: true,
																size: "small",
																label: "Deadline",
																error:
																	!!errors.deadline &&
																	!!touched.deadline,
																helperText:
																	!!errors.deadline &&
																	touched.deadline
																		? errors.deadline
																		: "",
															},
														}}
														onChange={(val) => {
															if (val) {
																const dueDate =
																	new Date(
																		val as string
																	);
																const validityDate =
																	addWeeks(
																		dueDate,
																		3
																	);
																setFieldValue(
																	"validityDate",
																	validityDate
																);
															}
														}}
													/>
													<CustomDatePicker
														disabled={
															!isUserAbleToUpdateAllFields
														}
														name="validityDate"
														disablePast
														sx={style.tenderInput}
														minDate={
															values.deadline
														}
														slotProps={{
															textField: {
																required: true,
																size: "small",
																label: "Validity date",
																error:
																	!!errors.validityDate &&
																	!!touched.validityDate,
																helperText:
																	!!errors.validityDate &&
																	touched.validityDate
																		? errors.validityDate
																		: "",
															},
														}}
													/>
												</LocalizationProvider>
												<CustomInput
													disabled={
														!isUserAbleToUpdateAllFields
													}
													required
													label="Tender Name"
													name="tenderName"
													value={values.tenderName}
													onChange={handleChange}
													size="small"
													sx={style.tenderInput}
													errorText={
														errors.tenderName
													}
													touched={touched.tenderName}
												/>
												<Box
													sx={{
														display: "flex",
														gap: "24px",
													}}
												>
													<FormControl
														required
														disabled={
															!isUserAbleToUpdateAllFields
														}
														sx={style.radioGroup}
													>
														<FormLabel
															sx={
																style.radioGroupLabel
															}
														>
															Direction
														</FormLabel>
														<RadioGroup
															aria-labelledby="direction"
															value={
																values.direction
															}
															name="direction"
															row
															onChange={
																handleChange
															}
														>
															<FormControlLabel
																value="PPA"
																control={
																	<Radio />
																}
																label="PPA"
															/>
															<FormControlLabel
																value="CPPA"
																control={
																	<Radio />
																}
																label="CPPA"
															/>
														</RadioGroup>
													</FormControl>
													<FormControl
														required
														disabled={
															!isUserAbleToUpdateAllFields
														}
														sx={style.radioGroup}
													>
														<FormLabel
															sx={
																style.radioGroupLabel
															}
														>
															Transaction Type
														</FormLabel>
														<RadioGroup
															aria-labelledby="transaction-type"
															value={
																values.transactionType
															}
															name="transactionType"
															row
															onChange={
																handleChange
															}
														>
															<FormControlLabel
																value="Physical"
																control={
																	<Radio />
																}
																label="Physical"
															/>
															<FormControlLabel
																value="Financial"
																control={
																	<Radio />
																}
																label="Financial"
															/>
														</RadioGroup>
													</FormControl>
												</Box>
												<Box
													sx={{
														display: "flex",
														gap: "24px",
													}}
												>
													<FormControl
														required
														sx={style.radioGroup}
													>
														<FormLabel
															sx={
																style.radioGroupLabel
															}
														>
															Pricing stage
														</FormLabel>
														<RadioGroup
															value={
																values.pricing_stage
															}
															name="pricing_stage"
															row
															onChange={
																handleChange
															}
														>
															<FormControlLabel
																value="INDICATIVE"
																control={
																	<Radio />
																}
																label="Indicative"
															/>
															<FormControlLabel
																value="FIRM"
																control={
																	<Radio />
																}
																label="Firm"
															/>
														</RadioGroup>
													</FormControl>
												</Box>
												<Box
													sx={{
														display: "flex",
														gap: "24px",
													}}
												>
													<FormControl
														required
														sx={style.radioGroup}
													>
														<FormLabel
															sx={
																style.radioGroupLabel
															}
														>
															Negative Price
															Clause
														</FormLabel>
														<RadioGroup
															value={
																values.has_negative_price_clause
															}
															name="has_negative_price_clause"
															row
															onChange={
																handleChange
															}
														>
															<FormControlLabel
																value={false}
																control={
																	<Radio />
																}
																label="No"
															/>
															<FormControlLabel
																value={true}
																control={
																	<Radio />
																}
																label="Yes"
															/>
														</RadioGroup>
													</FormControl>
													<FormControl
														required
														sx={style.radioGroup}
													>
														<FormLabel
															sx={
																style.radioGroupLabel
															}
														>
															Balancing
															Responsible Party
														</FormLabel>
														<RadioGroup
															value={
																values.is_balancing_responsible_party
															}
															name="is_balancing_responsible_party"
															row
															onChange={
																handleChange
															}
														>
															<FormControlLabel
																value={false}
																control={
																	<Radio />
																}
																label="No"
															/>
															<FormControlLabel
																value={true}
																control={
																	<Radio />
																}
																label="Yes"
															/>
														</RadioGroup>
													</FormControl>
												</Box>
												<Box>
													<DropZoneInput
														renderPreview={
															HistoricalDataFilePreview
														}
														setSubmitting={
															setSubmitting
														}
														label={
															"historical production data"
														}
														name={"historicalData"}
														value={
															values.historicalData.map(
																(file) => ({
																	...file,
																	latest_renewex_submission:
																		tender?.latest_renewex_submission?.files?.find(
																			({
																				id,
																			}) =>
																				file.id ===
																				id
																		) &&
																		props.mode ===
																			"edit"
																			? tender?.latest_renewex_submission
																			: undefined,
																})
															) || []
														}
														disabled={isSubmitting}
														setFieldValue={
															setFieldValue
														}
														accept={{
															"text/csv": [
																".csv",
															],
															"application/zip": [
																".zip",
															],
															"application/vnd.ms-excel":
																[".xls"],
															"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
																[".xlsx"],
														}}
														restrictionText={
															"Only .csv, excel files and zips at 500mb or less"
														}
														maxSize={
															500 * 1000 * 1000
														} // 500mb
													/>
												</Box>
												<Box sx={style.infoFields}>
													<If condition={tender?.id}>
														<If
															condition={
																tender
																	?.latest_renewex_submission
																	?.submission_id
															}
														>
															<FormLabel
																sx={{
																	marginBottom:
																		"10px",
																}}
															>
																Renewex
																id:&nbsp;
																{
																	tender
																		?.latest_renewex_submission
																		?.submission_id
																}
															</FormLabel>
														</If>
														<FormLabel>
															Gemforce opportunity
															id:&nbsp;
															{
																tender?.cor_opportunity_id
															}
														</FormLabel>
														<FormLabel>
															Gemforce sync
															date:&nbsp;
															{tender?.cor_sync_datetime &&
																format(
																	new Date(
																		tender?.cor_sync_datetime
																	),
																	"yyyy-MM-dd HH:mm"
																)}
														</FormLabel>
													</If>
												</Box>
											</Stack>
										</form>
									</Grid>
									<Grid
										item
										xs={9}
										justifyContent="flex-start"
									>
										<Box sx={{ width: "100%" }}>
											<Box
												sx={{
													borderBottom: 1,
													borderColor: "divider",
												}}
											>
												<Tabs
													value={activeTab}
													onChange={handleTabChange}
													aria-label="basic tabs example"
												>
													<Tab label="Sites" />
													<Tab
														label="Timeseries"
														disabled={
															props.mode !==
															"edit"
														}
														sx={{
															display:
																props.mode !==
																"edit"
																	? "none"
																	: "block",
														}}
													/>
												</Tabs>
											</Box>
											<CustomTabPanel
												value={activeTab}
												index={0}
											>
												<Box textAlign="start">
													<Box
														display="flex"
														justifyContent="flex-end"
														marginBottom={"24px"}
													>
														<Box
															display="flex"
															justifyContent="flex-end"
														>
															<If
																condition={
																	isUserAbleToUpdateAllFields
																}
															>
																<PrimaryButton
																	text="add sites"
																	type="button"
																	color="secondary"
																	sx={{
																		marginRight:
																			"16px",
																		width: 140,
																	}}
																	onClick={() =>
																		setOpenSiteSelection(
																			true
																		)
																	}
																>
																	<AddCircleIconOutline
																		sx={{
																			color: "#000",
																		}}
																	/>
																</PrimaryButton>
																<PrimaryButton
																	text="New Portfolio"
																	type="button"
																	color="secondary"
																	sx={{
																		marginRight:
																			"16px",
																		width: 180,
																	}}
																	onClick={() =>
																		setOpenPortfolioModal(
																			true
																		)
																	}
																	disabled={
																		!isUserAbleToUpdateAllFields
																	}
																>
																	<AddCircleIconOutline
																		sx={{
																			color: "#000",
																		}}
																	/>
																</PrimaryButton>
															</If>
														</Box>
													</Box>
													<Box
														sx={{
															height: "100%",
															maxHeight: "1000px",
															overflowY: "auto",
															overflowX: "auto",
														}}
													>
														<SiteAndPortfolioTable
															disabled={
																!isUserAbleToUpdateAllFields
															}
															virtualScrollable={
																false
															}
															getSelectedSites={() => {}}
															model={
																sortedPortfolioAndSites
															}
															updatePage={() => {}}
															page={1}
															isSelectable={false}
															showActions={
																isUserAbleToUpdateAllFields
															}
															showRefreshCluster={
																isUserAbleToRefreshCluster
															}
															onOpenPortfolioSiteModal={
																setSelectedPortfolioId
															}
															updateMessages={
																sitesUpdateMessages
															}
														/>
														{!sortedPortfolioAndSites.length && (
															<Box
																display="flex"
																sx={{
																	padding:
																		"15px",
																	marginTop:
																		"-360px",
																}}
																justifyContent="flex-start"
																alignItems="center"
															>
																No site yet
																<PrimaryButton
																	text="add sites"
																	type="button"
																	color="secondary"
																	sx={{
																		marginRight:
																			"16px",
																		marginLeft:
																			"10px",
																		width: 140,
																	}}
																	onClick={() =>
																		setOpenSiteSelection(
																			true
																		)
																	}
																	disabled={
																		!isUserAbleToUpdateAllFields
																	}
																>
																	<AddCircleIconOutline
																		sx={{
																			color: "#000",
																		}}
																	/>
																</PrimaryButton>
															</Box>
														)}
													</Box>
													<SiteSelectionFromGemforce
														disabled={
															!isUserAbleToUpdateAllFields
														}
														enableSearch={true}
														defaultSearch={
															values.account?.name
														}
														getTprSite={getTprSite}
														isSourcePortfolio={
															!!selectedPortfolioId
														}
														availableSites={
															availableSites || []
														}
														currentSites={
															(sitesFromPortfolio as Site[]) ||
															[]
														}
														keepMounted
														open={
															openSiteSelection ||
															!!selectedPortfolioId
														}
														isLoading={siteLoader}
														// @ts-ignore
														onClose={
															selectedPortfolioId
																? (
																		sites: Site[]
																  ) =>
																		handleCloseSiteSelectionOnPortfolio(
																			sites,
																			false
																		)
																: (
																		sites: Site[]
																  ) =>
																		handleCloseSiteSelection(
																			sites,
																			false,
																			(globalSites as Site[]) ||
																				[]
																		)
														}
													/>
													<PortfolioModal
														open={
															openPortfolioModal
														}
														closeModal={() =>
															setOpenPortfolioModal(
																false
															)
														}
													/>
												</Box>
											</CustomTabPanel>
											<CustomTabPanel
												value={activeTab}
												index={1}
											>
												<TimeseriesTable
													siteIds={globalSites?.map(
														(element) => element.id
													)}
													tender={tender}
												/>
											</CustomTabPanel>
										</Box>
									</Grid>
								</Grid>
								<Stack
									direction="row"
									justifyContent="flex-end"
									alignItems="center"
									spacing={2}
								>
									<PrimaryButton
										text="Cancel"
										type="button"
										disabled={isSubmitting}
										color="secondary"
										sx={{
											marginRight: "16px",
											width: 140,
										}}
										onClick={() => navigate(PATH.TENDERS)}
									>
										<HighlightOffIcon
											sx={{ color: "#000" }}
										/>
									</PrimaryButton>
									<If condition={!isUpdateDisabled}>
										<PrimaryButton
											text={actionTitle()}
											type="button"
											form="tender-form"
											color="primary"
											disabled={
												isSubmitting ||
												siteKnownStatusAreLoading ||
												siteKnownStatusError
											}
											sx={{
												marginRight: "16px",
												width: 140,
											}}
											onClick={() => handleSubmit()}
										>
											<AddCircleIcon
												sx={{ color: "#fff" }}
											/>
										</PrimaryButton>
									</If>
								</Stack>
								<AlertDialogModal
									handleApprove={() => {
										setFieldValue("bypassWarning", true);
										dispatch(createTenderWarning(""));
										handleSubmit();
									}}
									handleClose={() => {
										dispatch(createTenderWarning(""));
									}}
									isOpen={!!updatedTenderWarning}
									title={updatedTenderWarning || ""}
								/>
							</Box>
						);
					}}
				</Formik>
			</PageContent>
			<AfterCreationModal
				title="Tender created successfully"
				isOpen={createdTenderId > 0}
				content="What do you want to do next ?"
				handleApprove={() => {
					navigate(
						getPathWithParameters(PATH.PRICING_REQUEST, {
							tenderId: createdTenderId,
							"backPage?": "tenders",
						})
					);
					setCreatedTenderId(0);
				}}
				handleClose={() => {
					setCreatedTenderId(0);
					navigate(PATH.TENDERS);
				}}
			/>
		</>
	);
};

export default TenderForm;
