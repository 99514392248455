import type { GetBCVConfigResponseType } from "../../../../requests_geco/referentialApi/referentialApi.types";

export const mappingBCVConfig = (data?: GetBCVConfigResponseType[]) => {
	return data?.map((d) => {
		const contactConfigNames = d.bcv_config_data
			.map((configData) => configData.name)
			.join(", ");

		return {
			...d,
			name: d.name + " | " + contactConfigNames,
		};
	});
};
