import { useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { PrimaryButton } from "../CustomButton";
import { ConfirmationModal } from "./ConfirmationModal";
import { Spacer } from "../Spacer";

interface ConfirmNavigateProps {
	condition: boolean;
	navigate: () => void;
	saveChanges: () => void;
}
const ConfirmNavigate = ({
	condition,
	navigate,
	saveChanges,
}: ConfirmNavigateProps) => {
	const [confirmModal, setConfirmModal] = useState(false);

	return (
		<>
			<PrimaryButton
				text="Back"
				type="button"
				color="info"
				sx={{
					width: 106,
					color: "#171D21",
				}}
				onClick={() => {
					if (condition) {
						setConfirmModal(true);
					} else {
						navigate();
					}
				}}
			>
				<ChevronLeftIcon />
			</PrimaryButton>
			<ConfirmationModal
				isOpen={confirmModal}
				onClose={() => setConfirmModal(false)}
				onConfirm={() => {
					saveChanges();
					setConfirmModal(false);
				}}
				unConfirm={() => {
					navigate();
					setConfirmModal(false);
				}}
				title="Unsaved changes"
				icon={<WarningAmberIcon color={"warning"} />}
				text={
					<>
						<p>You have made changes that have not been saved.</p>
						<p>Proceeding will discard all modifications.</p>
						<Spacer gap={24} />
						Would you like to save your changes before leaving this
						page?
					</>
				}
			/>
		</>
	);
};

export default ConfirmNavigate;
