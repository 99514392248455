import _ from "lodash";
import {
	AdjustmentsFrequency,
	BCVConfigType,
	ContractDetailType,
	ContractPeriodType,
	ContractStatus,
	NegativePriceType,
	TradeType,
} from "../../../requests_geco/contractsApi/contractsApi.types";
import {
	getContractPeriodFromId,
	getContractPeriodIndexFromId,
} from "./common";

export interface GeneralTabFormikData {
	status?: ContractStatus;
	name?: string;
	goo_price?: number;
	trade_date?: string;
	start_date?: string;
	end_date?: string;
	test_phase?: boolean;
	trade_type?: TradeType;
	base_prod_capacity?: number;
	commodity?: string;
	has_adjustments?: boolean;
	invoice_decomposition?: boolean;
	adjustment_frequency?: AdjustmentsFrequency;
	has_negative_price: boolean;
	negative_price_index?: number;
	negative_price_value?: number;
	negative_price_type?: NegativePriceType;
	bcv_config?: BCVConfigType;
	bcv_config_goo?: BCVConfigType;
}

export const generalTabFormikInitialValueMapper = (
	data: ContractDetailType | undefined,
	contract_period_id: number
): GeneralTabFormikData => {
	return {
		status: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.status,
		name: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.name,
		goo_price: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.goo_price,
		trade_date: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.trade_date,
		start_date: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.start_date,
		end_date: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.end_date,
		test_phase: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.test_phase,
		trade_type: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.trade_type,
		base_prod_capacity: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.ppa_base_physical?.base_prod_capacity,
		commodity: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.commodity,
		has_adjustments:
			getContractPeriodFromId(
				data?.contract_periods || [],
				contract_period_id
			)?.invoicing?.has_adjustments || false,
		invoice_decomposition:
			getContractPeriodFromId(
				data?.contract_periods || [],
				contract_period_id
			)?.invoicing?.invoice_decomposition || false,
		adjustment_frequency: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.invoicing?.adjustment_frequency,
		// this a flag is only for showing extra fields
		has_negative_price: !!getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.negative_price?.value,
		negative_price_index: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.negative_price?.index,
		negative_price_value: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.negative_price?.value,
		negative_price_type: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.negative_price?.type,
		bcv_config: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.bcv_config,
		bcv_config_goo: getContractPeriodFromId(
			data?.contract_periods || [],
			contract_period_id
		)?.bcv_config_goo,
	};
};

export const formikDataToContractModelMapper = (
	data: GeneralTabFormikData,
	originData: ContractDetailType,
	contract_period_id: number
): ContractDetailType => {
	const updatedContract = _.cloneDeep(originData);
	const contractPeriodIndex = getContractPeriodIndexFromId(
		originData.contract_periods,
		contract_period_id
	);
	const updatedContractPeriod: ContractPeriodType = {
		...(getContractPeriodFromId(
			originData.contract_periods,
			contract_period_id
		) as ContractPeriodType),
		name: data.name as string,
		status: data.status,
		trade_date: data.trade_date as string,
		start_date: data.start_date as string,
		end_date: data.end_date as string,
		test_phase: data.test_phase as boolean,
		trade_type: data.trade_type as TradeType,
		commodity: data.commodity as string,
		goo_price: data.goo_price as number,
		bcv_config: {
			id: data.bcv_config?.id,
			name: data.bcv_config?.name?.split("|")[0].trimEnd(),
		},
		bcv_config_goo: {
			id: data.bcv_config_goo?.id,
			name: data.bcv_config_goo?.name?.split("|")[0].trimEnd(),
		},
	};

	// invoicing
	const newInvoicing = {
		...updatedContractPeriod.invoicing,
	};

	newInvoicing.has_adjustments = data.has_adjustments as boolean;
	newInvoicing.invoice_decomposition = data.invoice_decomposition as boolean;
	newInvoicing.adjustment_frequency =
		data.adjustment_frequency as AdjustmentsFrequency;

	updatedContractPeriod.invoicing = newInvoicing;

	// negative price
	if (data.has_negative_price) {
		const newNegativePrice = {
			...updatedContractPeriod.negative_price,
		};
		newNegativePrice.index = data.negative_price_index;
		newNegativePrice.value = data.negative_price_value;
		newNegativePrice.type = data.negative_price_type;
		updatedContractPeriod.negative_price = newNegativePrice;
	} else {
		delete updatedContractPeriod.negative_price;
	}

	if (data.trade_type === TradeType.PPA_BASE_PHYSICAL) {
		const newPpaBasePhysical = {
			...(updatedContractPeriod?.ppa_base_physical || {}),
		};
		newPpaBasePhysical.base_prod_capacity =
			data.base_prod_capacity as number;
		updatedContractPeriod.ppa_base_physical = newPpaBasePhysical;
	}

	updatedContract.contract_periods[contractPeriodIndex] =
		updatedContractPeriod;

	return updatedContract;
};
