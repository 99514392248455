import { Formik, type FormikProps } from "formik";

import Counterparty from "./CounterPartyFields";
import {
	ContractDetailType,
	ErrorType,
} from "../../../requests_geco/contractsApi/contractsApi.types";
import {
	CounterpartyFormik,
	counterpartyTabFormikInitialValueMapper,
} from "../../contract_validation/formik/counterpartyFormik";
import { FormikAutoSubmit } from "../../../common/components/FormikAutoSubmit";
import {
	useLazyGetPartyDetailQuery,
	useLazySearchPartiesQuery,
} from "../../../requests_geco/gepoTprProxyApi";

export interface CounterpartyFormProps {
	formRef: React.Ref<FormikProps<CounterpartyFormik>>;
	contract: ContractDetailType | undefined;
	onSubmit: (data: CounterpartyFormik) => void;
}

const CounterpartyForm = ({
	formRef,
	contract,
	onSubmit,
}: CounterpartyFormProps) => {
	const [
		getPartyDetail,
		{
			data: detailParty,
			isLoading: isDetailLoading,
			error: detailPartyError,
		},
	] = useLazyGetPartyDetailQuery();
	const [searchParties, { data: parties, isLoading: isSearchLoading }] =
		useLazySearchPartiesQuery();

	return (
		<Formik
			validateOnChange={false}
			validateOnBlur={false}
			enableReinitialize
			initialValues={counterpartyTabFormikInitialValueMapper(contract)}
			onSubmit={onSubmit}
			innerRef={formRef}
		>
			{({
				handleChange,
				setFieldValue,
				handleSubmit,
				values,
				submitForm,
			}) => (
				<form onSubmit={handleSubmit} id="counterPartyForm">
					<FormikAutoSubmit onSubmitForm={submitForm} values={values}>
						<Counterparty
							values={values}
							parties={parties?.partyResults || []}
							detailParty={detailParty}
							errorMessage={detailPartyError as ErrorType}
							isLoading={isDetailLoading || isSearchLoading}
							setFieldValue={setFieldValue}
							handleChange={handleChange}
							searchParties={searchParties}
							getPartyDetail={getPartyDetail}
							label="Counterpart"
						/>
					</FormikAutoSubmit>
				</form>
			)}
		</Formik>
	);
};

export default CounterpartyForm;
