import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";

const style = {
	button: {
		display: "flex",
		alignItems: "center",
		whiteSpace: "nowrap",
		gap: "4px",
	},
};

const CopyToClipboardButton = ({
	label,
	text,
	maxChars = 300,
}: {
	label: string;
	text: string;
	maxChars?: number;
}) => {
	const [isActive, setIsActive] = useState(false);
	const [isExpand, setIsExpand] = useState(false);
	const isMaxChars = label?.length >= maxChars;

	if (!text) {
		return null;
	}

	useEffect(() => {
		if (isActive) {
			const interval = setInterval(() => {
				setIsActive(false);
			}, 5000);
			return () => clearInterval(interval);
		}
	}, [isActive]);

	const handleClick = () => {
		navigator.clipboard.writeText(text);
		setIsActive(true);
	};

	return (
		<Box sx={style.button}>
			<Box
				sx={{
					cursor: "pointer",
				}}
			>
				<ContentCopyIcon onClick={handleClick} />
			</Box>
			{isActive && "Copied!"}
			{!isActive && (
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
					}}
				>
					<Box
						sx={{
							overflow: "hidden",
							textOverflow: "ellipsis",
							whiteSpace: "pre-wrap",
							width: "400px",
						}}
					>
						{isMaxChars && !isExpand
							? label.slice(0, maxChars) + "..."
							: label}
					</Box>

					{isMaxChars && (
						<a
							href="#"
							onClick={() =>
								setIsExpand((prevState) => !prevState)
							}
						>
							<Box
								sx={{
									display: "flex",
									mt: 2,
								}}
							>
								{isExpand ? (
									<ExpandLessIcon />
								) : (
									<ExpandMoreIcon />
								)}
								View more
							</Box>
						</a>
					)}
				</Box>
			)}
		</Box>
	);
};

export default CopyToClipboardButton;
