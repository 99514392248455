import { useCallback, useState } from "react";
import { CircularProgress } from "@mui/material";
import { Formik } from "formik";

import {
	ContractDetailType,
	ContractPeriodType,
} from "../../../../requests_geco/contractsApi/contractsApi.types";
import GeneralTabForm from "./GeneralTabForm";
import {
	GeneralTabFormikData,
	generalTabFormikInitialValueMapper,
	formikDataToContractModelMapper,
} from "../../formik/generalTabFormik";
import { If } from "../../../../common/components/If";
import { NegativePriceIndex } from "../../../../requests_geco/referentialApi/referentialApi.types";
import { HorizonChange } from "../../../horizon_change/HorizonChange";
import { useIsUserWithinGroups } from "../../../../common/hooks/useIsUserWithinGroups";
import { Groups } from "../../../authentication/authentication.constant";

export interface GeneralTabProps {
	contract?: ContractDetailType;
	contractPeriod: ContractPeriodType | undefined;
	onSaveDraftContract: (contract: ContractDetailType) => void;
	negativePriceIndexes: NegativePriceIndex[] | undefined;
}

const GeneralTab = ({
	contract,
	contractPeriod,
	negativePriceIndexes,
	onSaveDraftContract,
}: GeneralTabProps) => {
	const { isUserAuthorized } = useIsUserWithinGroups();
	const [isHorizonChangeOpen, setHorizonChangeOpen] = useState(false);

	const isUserAuthorizedBool = isUserAuthorized([
		Groups.geco_admin,
		Groups.geco_trader,
	]);

	const onUpdateGeneralTab = useCallback(
		(data: GeneralTabFormikData) => {
			if (isUserAuthorizedBool) {
				return onSaveDraftContract(
					formikDataToContractModelMapper(
						data,
						contract as ContractDetailType,
						contractPeriod?.id!
					)
				);
			}
		},
		[contract, contractPeriod?.id]
	);

	return (
		<>
			<If condition={!contract}>
				<CircularProgress
					sx={{ marginTop: "40px" }}
					data-testid="loader"
				/>
			</If>
			<If condition={!!contract}>
				<Formik
					enableReinitialize
					initialValues={generalTabFormikInitialValueMapper(
						contract as ContractDetailType,
						contractPeriod?.id!
					)}
					onSubmit={onUpdateGeneralTab}
				>
					{() => (
						<GeneralTabForm
							{...{
								negativePriceIndexes,
								setHorizonChangeOpen,
							}}
						/>
					)}
				</Formik>
			</If>
			<HorizonChange
				contractPeriod={contractPeriod}
				isOpen={isHorizonChangeOpen}
				onClose={() => setHorizonChangeOpen(false)}
			/>
		</>
	);
};

export default GeneralTab;
