import { useState, useRef } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	IconButton,
	Box,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { enqueueSnackbar } from "notistack";

import { PrimaryButton } from "../../common/components/CustomButton";
import CounterPartyForm from "./forms/CounterPartyForm";

import { useUpdateOneContractMutation } from "../../requests_geco/contractsApi";
import { useRtkQueryDynamicEndpoint } from "../../common/hooks/useRtkQueryDynamicEndpoint";
import { formatApiErrorMessage } from "../../common/utils/formatApiErrorMessage";

import type { FormikProps } from "formik";
import type { ContractDetailType } from "../../requests_geco/contractsApi/contractsApi.types";
import type { CounterpartyFormik } from "../contract_validation/formik/counterpartyFormik";

export interface CounterPartModalProps {
	selectedContract: ContractDetailType | null;
	handleCLose: () => void;
}

export const CounterPartModal = ({
	selectedContract,
	handleCLose,
}: CounterPartModalProps) => {
	const formRef = useRef<FormikProps<ContractDetailType> | null>(null);
	const [selectedCounterParty, setSelectedCounterParty] =
		useState<CounterpartyFormik | null>(null);

	const [updateContractBase, { isLoading }] = useUpdateOneContractMutation();

	const updateContract = useRtkQueryDynamicEndpoint(updateContractBase);

	const handleUpdateContract = () => {
		const newCounterParty = {
			...selectedContract,
			id: selectedContract?.id!,
			party: selectedCounterParty?.party!,
		};

		updateContract(newCounterParty)
			.unwrap()
			.then(() => {
				enqueueSnackbar(
					`contract ${selectedContract?.id} updated successfully`,
					{
						variant: "success",
						autoHideDuration: 3000,
					}
				);
				handleCLose();
			})
			.catch((rejectedValueOrSerializedError) => {
				const errors = formatApiErrorMessage(
					rejectedValueOrSerializedError
				);
				if (Array.isArray(errors)) {
					errors.map((error) =>
						enqueueSnackbar(error, {
							variant: "error",
							autoHideDuration: 3000,
						})
					);
				} else {
					enqueueSnackbar(errors, {
						variant: "error",
						autoHideDuration: 3000,
					});
				}
			});
	};

	if (!selectedContract) return null;
	return (
		<Dialog fullWidth onClose={handleCLose} open={!!selectedContract}>
			<DialogTitle
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Box>
					<EditIcon color="primary" sx={{ mr: 1 }} />
					Edit Contract
				</Box>
				<IconButton aria-label="close" onClick={handleCLose}>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent
				sx={{
					overflow: "unset",
				}}
			>
				<CounterPartyForm
					//@ts-ignore
					formRef={formRef}
					contract={selectedContract}
					onSubmit={setSelectedCounterParty}
				/>
			</DialogContent>
			<DialogActions>
				<PrimaryButton
					onClick={handleCLose}
					text="Cancel"
					type="button"
					color="secondary"
				/>
				<PrimaryButton
					onClick={handleUpdateContract}
					text="Valid"
					type="submit"
					loader={isLoading}
					isDisabled={
						!(formRef.current?.isValid && formRef.current?.dirty)
					}
					form="counterPartyForm"
				>
					<CheckIcon />
				</PrimaryButton>
			</DialogActions>
		</Dialog>
	);
};
