import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import { errorColor, successColor, warningColor } from "../../core/theme";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import CancelIcon from "@mui/icons-material/Cancel";
import CreateIcon from "@mui/icons-material/Create";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import * as yup from "yup";

export enum PricingAction {
	FAIL = "Fail",
	REFRESH = "Refresh",
	APPROVE_INDICATIVE = "Approve Indicative",
	REQUEST_FIRM = "Request Firm",
	APPROVE_FIRM = "Approve Firm",
	CREATE_OFFER = "Create Offer",
	EXPIRE = "Expire",
	CLOSE = "Close",
	WIN = "Win",
	LOSE = "Lose",
	CANCEL = "Cancel",
	CHANGE_VALIDITY_DATE = "Change Validity Date",
}

export enum PricingRunStatusTransitions {
	CREATE_OFFER = "CREATE_OFFER",
	BOOK = "BOOK",
}

export type Status =
	| "TODO"
	| "OPEN"
	| "FIXING_REQUESTED"
	| "FIXED"
	| "FIXED_APPROVED"
	| "FIXED_TIMED_OUT"
	| "BETA_REQUESTED"
	| "BETA_FIXED"
	| "BETA_APPROVED"
	| "BETA_TIMED_OUT"
	| "EXPIRED"
	| "UNDER_PRICING"
	| "UNDER_MANUAL_PRICING"
	| "FAILED"
	| "TIMED_OUT"
	| "UNDER_REVIEW"
	| "INDICATIVE"
	| "FIRM_REQUESTED"
	| "FIRM"
	| "UNDER_OFFER"
	| "UNDER_BOOKING"
	| "WON"
	| "LOST"
	| "CANCELLED"
	| "UNDER_BOOKING"
	| "BOOKED"
	| "BOOKING_FAILED";

const PRICING_TENDER_STATUS = {
	TODO: {
		color: "rgb(0, 122, 205)",
		icon: CreateIcon,
		text: "TODO",
		status: "TODO",
	},
	OPEN: {
		color: "rgb(0, 122, 205)",
		icon: CreateIcon,
		text: "OPEN",
		status: "OPEN",
	},
	UNDER_MANUAL_PRICING: {
		color: "rgb(0, 122, 205)",
		icon: HourglassBottomIcon,
		text: "UNDER MANUAL PRICING",
		status: "UNDER_MANUAL_PRICING",
	},
	UNDER_PRICING: {
		color: "rgb(0, 122, 205)",
		icon: HourglassBottomIcon,
		text: "UNDER PRICING",
		status: "UNDER_PRICING",
	},
	PENDING_CLUSTER: {
		color: "rgb(0, 122, 205)",
		icon: HourglassBottomIcon,
		text: "PENDING CLUSTER",
		status: "PENDING_CLUSTER",
	},
	DATA_CLEANING_IN_PROGRESS: {
		color: "rgb(0, 122, 205)",
		icon: HourglassBottomIcon,
		text: "DATA CLEANING IN PROGRESS",
		status: "DATA_CLEANING_IN_PROGRESS",
	},
	TO_BE_CHECKED: {
		color: errorColor,
		icon: ErrorIcon,
		text: "TO_BE_CHECKED",
		status: "TO_BE_CHECKED",
	},
	FAILED: {
		color: errorColor,
		icon: ErrorIcon,
		text: "FAILED",
		status: "FAILED",
	},
	DATA_CLEANING_FAILED: {
		color: errorColor,
		icon: ErrorIcon,
		text: "DATA CLEANING FAILED",
		status: "DATA_CLEANING_FAILED",
	},
	TIMED_OUT: {
		color: errorColor,
		icon: ErrorIcon,
		text: "TIMED OUT",
		status: "TIMED_OUT",
	},
	ONGOING: {
		color: successColor,
		icon: DoneIcon,
		text: "ONGOING",
		status: "ONGOING",
	},
	UNDER_REVIEW: {
		color: successColor,
		icon: DoneIcon,
		text: "UNDER REVIEW",
		status: "UNDER_REVIEW",
	},
	PRICING_DONE: {
		color: successColor,
		icon: DoneIcon,
		text: "PRICING_DONE",
		status: "PRICING_DONE",
	},
	INDICATIVE: {
		color: successColor,
		icon: DoneIcon,
		text: "INDICATIVE",
		status: "INDICATIVE",
	},
	FIRM_REQUESTED: {
		color: successColor,
		icon: DoneIcon,
		text: "FIRM REQUESTED",
		status: "FIRM_REQUESTED",
	},
	FIRM: {
		color: successColor,
		icon: DoneIcon,
		text: "FIRM",
		status: "FIRM",
	},
	UNDER_OFFER: {
		color: successColor,
		icon: DoneIcon,
		text: "UNDER OFFER",
		status: "UNDER_OFFER",
	},
	WON: {
		color: successColor,
		icon: DoneIcon,
		text: "WON",
		status: "WON",
	},
	BETA_WON: {
		color: successColor,
		icon: DoneIcon,
		text: "WON",
		status: "BETA_WON",
	},
	BETA_REQUESTED: {
		color: successColor,
		icon: DoneIcon,
		text: "BETA REQUESTED",
		status: "BETA_REQUESTED",
	},
	BETA_FIX_REQUESTED: {
		color: successColor,
		icon: DoneIcon,
		text: "BETA REQUESTED",
		status: "BETA_REQUESTED",
	},
	BETA_FIXED: {
		color: successColor,
		icon: DoneIcon,
		text: "BETA FIXED",
		status: "BETA_FIXED",
	},
	BETA_APPROVED: {
		color: successColor,
		icon: DoneIcon,
		text: "BETA APPROVED",
		status: "BETA_APPROVED",
	},
	BETA_FIX_APPROVED: {
		color: successColor,
		icon: DoneIcon,
		text: "BETA APPROVED",
		status: "BETA_APPROVED",
	},
	LOST: {
		color: successColor,
		icon: DoneIcon,
		text: "LOST",
		status: "LOST",
	},
	CANCELLED: {
		color: successColor,
		icon: CancelIcon,
		text: "CANCELLED",
		status: "CANCELLED",
	},
	EXPIRED: {
		color: "rgb(0, 122, 205)",
		icon: CloseIcon,
		text: "EXPIRED",
		status: "EXPIRED",
	},
	BOOKED: {
		color: successColor,
		icon: DoneIcon,
		text: "BOOKED",
		status: "BOOKED",
	},
	UNDER_BOOKING: {
		color: "rgb(0, 122, 205)",
		icon: HourglassBottomIcon,
		text: "UNDER BOOKING",
		status: "UNDER_BOOKING",
	},
	BOOKING_FAILED: {
		color: errorColor,
		icon: ErrorIcon,
		text: "BOOKING FAILED",
		status: "BOOKING_FAILED",
	},
	FIXING_REQUESTED: {
		color: successColor,
		icon: DoneIcon,
		text: "FIXING REQUESTED",
		status: "FIXING_REQUESTED",
	},
	FIXED: {
		color: successColor,
		icon: DoneIcon,
		text: "FIXED",
		status: "FIXED",
	},
	FIXED_APPROVED: {
		color: successColor,
		icon: DoneIcon,
		text: "FIXED APPROVED",
		status: "FIXED_APPROVED",
	},
	FIXED_TIMED_OUT: {
		color: warningColor,
		icon: WarningAmberIcon,
		text: "FIXING TIME OUT",
		status: "FIXING_TIME_OUT",
	},
	BETA_TIMED_OUT: {
		color: warningColor,
		icon: WarningAmberIcon,
		text: "BETA TIME OUT",
		status: "BETA_TIMED_OUT",
	},
};

export const STATUS_CANNOT_EXPORT_INPUT = ["UNDER_PRICING"];

export const STATUS_CANNOT_EXPORT_RESULT = [
	"UNDER_PRICING",
	"FAILED",
	"TIMED_OUT",
];

export const DEFAULT_PARTY_NAME = "ATTENBOC";

export type PricingError = "OVERLAPPING_PRICING";

export const ACTION_PRICING_ERROR = {
	OVERLAPPING_PRICING:
		"you have an overlapping data with the following pricing :",
};

export const formatGraphData = (type: string, data: any) => {
	if (type === "cluster") {
		return [
			{
				id: "PX_CLUS",
				name: "PX_CLUS",
				data: data.proxy,
				animation: false,
				width: 1,
			},
			{
				id: "MK_CLUS",
				name: "MK_CLUS",
				data: data.p50,
				width: 1,
			},
			{
				id: "PX_AVERAGE",
				name: "PX_AVERAGE",
				data: data.average_monthly_proxy,
				width: 1,
			},
		];
	}
	return [
		{
			id: `RE_${type}`,
			name: `RE_${type}`,
			data: data.proxy,
			width: 1,
		},
		{
			id: `MK_RE_${type}`,
			name: `MK_RE_${type}`,
			data: data.p50,
			width: 1,
		},
	];
};

export const formatWindSpeedRadiationGraphData = (type: string, data: any) => {
	if (type === "cluster") {
		return [
			{
				id: "PX_CLUS",
				name: "PX_CLUS",
				type: "scatter",
				data: data.wind_speed_radiation,
				animation: false,
			},
		];
	}
	return [
		{
			id: `RE_${type}`,
			name: `RE_${type}`,
			type: "scatter",
			data: data.wind_speed_radiation,
			animation: false,
		},
	];
};

export const formatFrequencyGraphData = (data: any) => {
	let result: any = [];
	if (data.length > 0) {
		data.filter((element: any) => {
			if (element.id.startsWith("MK_") || element.id == "PX_AVERAGE")
				return false;
			return true;
		}).forEach((s: any) => {
			result = [
				...result,
				{
					name: s.name,
					type: "histogram",
					xAxis: 1,
					yAxis: 1,
					baseSeries: s.id,
					zIndex: -1,
				},

				{
					visible: false,
					...s,
					showInLegend: false,
					id: s.id,
				},
			];
		});
	}

	return result;
};

export const getStatusValues = (status: Status) => {
	if (status in PRICING_TENDER_STATUS) {
		return PRICING_TENDER_STATUS[status];
	}

	return {
		color: "rgb(0, 122, 205)",
		icon: DoneIcon,
		text: status,
		status: status,
	};
};

export const DISPLAY_TECHNO_NAME_MAP: Record<string, string> = {
	wind_offshore: "Wind Speed",
	wind_onshore: "Wind Speed",
	solar: "Radiation",
};
export const schema = yup.object({
	baseloadMid: yup.number().required().positive(),
	baseloadSpread: yup.number().required(),
	baseloadPrice: yup.number().required().positive(),
	validityInMinutes: yup.number().required().positive().integer(),
	goo_price: yup.number().nullable(),
	goo_cross: yup.number().nullable(),
});
