import { Box, Grid, SxProps, Theme, Typography } from "@mui/material";
import { PricingRequestData, PricingRequestDataKeys } from "./PricingStep";
import { borderNeutralSecondary, white } from "../../../core/theme";
import { PrimaryButton } from "../../../common/components/CustomButton";
import SiteSelection from "../../../common/components/SiteSelection";

import ModeEdit from "@mui/icons-material/ModeEdit";
import { useMemo, useState } from "react";
import {
	getPortfoliosFromPricingRequestForm,
	getSitesFromPricingRequestForm,
} from "../utils/helpers.utils";
import { Site, Portfolio } from "../../sites/sites.module";
import { useSelector } from "react-redux";
import { selectFeatureFlags } from "../../parameters/ParametersPage.selector";
import { FeatureName } from "../../../common/hooks/useFeatureFlags";
import { format } from "date-fns";
import { If } from "../../../common/components/If";

const style: { [key: string]: SxProps<Theme> } = {
	overviewGridItem: {
		paddingRight: "24px",
		marginBottom: "3em",
		"&:last-of-type": {
			paddingRight: "0px",
		},
	},
	overviewBox: {
		backgroundColor: white,
		border: `1px solid ${borderNeutralSecondary}`,
		textAlign: "left",
		padding: "16px 16px 5em",
		height: "100%",
		position: "relative",
	},
	overviewBoxTitle: {
		fontWeight: "bold",
		marginBottom: "10px",
	},
	overViewFloatingButton: {
		position: "absolute",
		bottom: "0px",
		left: "0px",
		margin: "16px",
	},
};

export interface OverviewPricingStepProps {
	pricingValues: PricingRequestData;
	sitesValues: (Site | Portfolio)[];
	setActiveStep: Function;
}

export type PricingRequest = Omit<OverviewPricingStepProps, "setActiveStep">;

export default function OverviewStep(props: OverviewPricingStepProps) {
	const { pricingValues, sitesValues, setActiveStep } = props;
	const features = useSelector(selectFeatureFlags);
	const isGooOnlyEnabled = features[FeatureName.is_goo_only_enabled];
	const [openSiteSelection, setOpenSiteSelection] = useState<any>(false);
	const isFixPricing = () => {
		return pricingValues.pricingType.filter(
			(pt: { id: number; name: string }) => pt.name == "Fix"
		).length;
	};
	// it will be used when we add gooOnly
	const isGooFixPricing = () => {
		return pricingValues.gooPricingType.filter(
			(pt: { id: number; name: string }) => pt.name == "Fix"
		).length;
	};
	const isClickPricing = () => {
		return pricingValues.pricingType.filter(
			(pt: { id: number; name: string }) => pt.name == "Click"
		).length;
	};
	const getListOfPricingTypes = () => {
		return pricingValues.pricingType.filter(
			(pt: { id: number; name: string }) =>
				pt.name != "Fix" && pt.name != "Click"
		);
	};
	const convertToMonthYearFormat = (date: any) => {
		return (
			new Date(date).getMonth() + 1 + "/" + new Date(date).getFullYear()
		);
	};

	const granularities = useMemo(() => {
		const all = [];
		if (pricingValues.granularityMonthly) {
			all.push("Monthly");
		}
		if (pricingValues.granularityCal) {
			all.push("CAL");
		}
		if (pricingValues.granularityQuarter) {
			all.push("Quarter");
		}
		if (pricingValues.granularityWholeHorizon) {
			all.push("Whole Horizon");
		}
		return all.join(", ");
	}, [pricingValues]);

	// it will be used when we add gooOnly
	const gooGranularities = useMemo(() => {
		const all = [];
		if (pricingValues.gooGranularityMonthly) {
			all.push("Monthly");
		}
		if (pricingValues.gooGranularityCal) {
			all.push("CAL");
		}
		if (pricingValues.gooGranularityQuarter) {
			all.push("Quarter");
		}
		if (pricingValues.gooGranularityWholeHorizon) {
			all.push("Whole Horizon");
		}
		return all.join(", ");
	}, [pricingValues]);

	return (
		<Grid container justifyContent="start" alignItems="stretch">
			<Grid
				item
				xs={isGooOnlyEnabled ? 4 : 6}
				sx={style.overviewGridItem}
			>
				<Box sx={style.overviewBox}>
					<Typography
						variant="h3"
						component="h2"
						sx={style.overviewBoxTitle}
					>
						Sites
					</Typography>
					<Typography component="p">
						{
							getPortfoliosFromPricingRequestForm(sitesValues)
								.length
						}{" "}
						portfolio(s)
					</Typography>
					<Typography component="p">
						{getSitesFromPricingRequestForm(sitesValues).length}{" "}
						site(s) targeted
					</Typography>
					<PrimaryButton
						onClick={() => setOpenSiteSelection(true)}
						disabled={sitesValues?.length === 0}
						text="See all"
						type="button"
						color="secondary"
						sx={{ marginTop: "15px" }}
					/>
					{openSiteSelection && (
						<SiteSelection
							localModel={sitesValues}
							title="Sites selected"
							enableSearch={false}
							open={openSiteSelection}
							enableSiteSelection={false}
							onClose={() => setOpenSiteSelection(false)}
							isLoading={false}
							onUpdatePage={() => {}}
							currentPage={0}
							onSearch={() => {}}
						/>
					)}

					<PrimaryButton
						text="Edit"
						type="button"
						color="secondary"
						sx={{
							...style.overViewFloatingButton,
							width: 160,
						}}
						onClick={() => setActiveStep(0)}
					>
						<ModeEdit sx={{ color: "#334551" }} />
					</PrimaryButton>
				</Box>
			</Grid>
			<Grid
				item
				xs={isGooOnlyEnabled ? 4 : 6}
				sx={style.overviewGridItem}
			>
				<Box sx={style.overviewBox}>
					<Typography
						variant="h3"
						component="h2"
						sx={style.overviewBoxTitle}
					>
						Power pricing
					</Typography>
					<Typography component="p">
						Fix: {isFixPricing() ? "Yes" : "No"}
					</Typography>
					<Typography component="p">
						Click: {isClickPricing() ? "Yes" : "No"}
					</Typography>
					<Typography component="p">Index:</Typography>
					{getListOfPricingTypes().map((pt, key) => (
						<Typography component="li" key={key}>
							{pt.geco_name || pt.name}
						</Typography>
					))}
					<Typography component="p">GOO:</Typography>
					<Typography component="li">
						Enabled: {pricingValues.ppaWithGooSwitch ? "Yes" : "No"}{" "}
					</Typography>
					{pricingValues.ppaWithGooSwitch && (
						<Typography component="li">
							Power Adjustement:{" "}
							{pricingValues.gooPowerAdjustment}{" "}
						</Typography>
					)}
					<Typography component="p">
						Period:{" "}
						{convertToMonthYearFormat(pricingValues.startDate)} -{" "}
						{convertToMonthYearFormat(pricingValues.endDate)}
					</Typography>
					<Typography component="p">
						<Typography component="span">
							{granularities}
						</Typography>
					</Typography>
					{pricingValues.baseOptionSwitch &&
						pricingValues.baseCapacity && (
							<Typography component="p">
								Base Capacity Volume:{" "}
								{pricingValues.baseCapacity}
							</Typography>
						)}

					<Typography component="p">
						Clickability Options:{" "}
						{pricingValues.clickOptionSwitch ? "Yes" : "No"}
					</Typography>
					{pricingValues.clickOptionSwitch && (
						<Typography component="p">
							Granularity:
							<Typography component="ul">
								{pricingValues.clickGranularity?.map(
									(granularity) => (
										<>
											<If
												condition={
													granularity == "NoSplit"
												}
												key={granularity}
											>
												<li>NoSplit</li>
											</If>
											<If
												condition={
													granularity != "NoSplit"
												}
												key={granularity}
											>
												<li>
													{granularity} with number of
													clicks:
													{" " +
														Number(
															pricingValues[
																`clickCount${granularity}` as PricingRequestDataKeys
															]
														)}
												</li>
											</If>
										</>
									)
								)}
							</Typography>
						</Typography>
					)}
					<PrimaryButton
						text="Edit"
						type="button"
						color="secondary"
						sx={{
							...style.overViewFloatingButton,
							width: 160,
						}}
						onClick={() => setActiveStep(1)}
					>
						<ModeEdit sx={{ color: "#334551" }} />
					</PrimaryButton>
				</Box>
			</Grid>
			{/* it will be used when we add gooOnly*/}
			{isGooOnlyEnabled && (
				<Grid item xs={4} sx={style.overviewGridItem}>
					<Box sx={style.overviewBox}>
						<Typography
							variant="h3"
							component="h2"
							sx={style.overviewBoxTitle}
						>
							GOO pricing
						</Typography>
						<Typography component="p">
							Fix Price: {isGooFixPricing() ? "Yes" : "No"}
						</Typography>
						<Typography component="p">
							Formula: {pricingValues.formula}
						</Typography>
						<Typography component="p">
							Period:{" "}
							{convertToMonthYearFormat(
								pricingValues.gooStartDate
							)}{" "}
							-{" "}
							{convertToMonthYearFormat(pricingValues.gooEndDate)}
						</Typography>
						<Typography component="p">
							<Typography component="span">
								{gooGranularities}
							</Typography>
						</Typography>
						<Typography component="p">
							Clickability Options:{" "}
							{pricingValues.gooClickSwitch ? "Yes" : "No"}
						</Typography>
						{pricingValues.gooClickSwitch && (
							<Typography component="p">
								Granularity: {pricingValues.gooClickGranularity}
							</Typography>
						)}
						{pricingValues.gooClickSwitch && (
							<Typography component="p">
								Number of clicks: {pricingValues.gooClickCount}
							</Typography>
						)}
						<Typography component="p">
							Click limit date:{" "}
							{format(pricingValues.clickLimitDate, "dd/MM/yyyy")}
						</Typography>
						<PrimaryButton
							text="Edit"
							type="button"
							color="secondary"
							sx={{
								...style.overViewFloatingButton,
								width: 160,
							}}
							onClick={() => setActiveStep(2)}
						>
							<ModeEdit sx={{ color: "#334551" }} />
						</PrimaryButton>
					</Box>
				</Grid>
			)}
		</Grid>
	);
}
